import { DOMAIN } from "../config/helper.js";

export const version = "1.0.11.d";

//#region ---------- AWS ----------

export let domain = "https://"+DOMAIN+"/api/v2/backend";
let uploadDomain = "https://"+DOMAIN+"/api/topup/receipt2";
let topupReceiptUploadDomain = "https://"+DOMAIN+"/api/topup/receipt-md5";
let fileDomain = "https://"+DOMAIN+"/public/receipt/";
let uploadsFolder = "https://"+DOMAIN+"/uploads/";
let uploadDepFile =  "https://"+DOMAIN+"/api/deposit/uploads";
let botDomain = "https://www.ogbot.site/api/v2/rest";
let updateCssVariablesDomain = "https://"+DOMAIN+"/api/updateCssVariable";
let saveOrOpdateCssDraftDomain = "https://"+DOMAIN+"/api/saveCssDraft";
let modifierUploadImageDomain = "https://"+DOMAIN+"/api/modifierUploadImage";
//#endregion

export {
  uploadDomain,
  fileDomain,
  botDomain,
  uploadsFolder,
  uploadDepFile,
  topupReceiptUploadDomain,
  updateCssVariablesDomain,
  saveOrOpdateCssDraftDomain,
  modifierUploadImageDomain,
};
