import React, { useState, useEffect, useRef } from "react";
import { PageHeader, Timeline, Tag, Tooltip, Button, Select } from "antd";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { reportAgentListV3, getAgentQR } from "../../config/networkConfig";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert } from "../../common/constants/sharedMsgBox";
import { useQRCode } from "react-hook-qrcode";
import QRCode from 'qrcode';
import style from "../../resource/style/style";

export default function QRcode() {
  const { Option } = Select;
  const { qrCode, refCode, role, userId, companyId } = useSelector((state) => state.login);
  const [copySuccess, setCopySuccess]       = useState("");
  const [agentOptions, setAgentOptions]     = useState([]);
  const [agentSelection, setAgentSelection] = useState(null);
  const [agentQRCode, setAgentQRCode]       = useState([]);
  const [qrDataUrl, setQrDataUrl]           = useState("");
  const [isAdminCS, setIsAdminCS]           = useState(false);
  const [loading, setLoading]               = useState(true);
  const reportAgentRef                      = useRef(null);

  const showCopyButton = !isAdminCS || (isAdminCS && agentSelection);

  useEffect(() => {
    if (role === "Admin_CS") {
      setIsAdminCS(true);
    } else {
      setIsAdminCS(false);
    }
  }, [role]);

  useEffect(() => {
    if (role === 'Admin_CS') {
      agentList();
      if (agentSelection) {
        agentQR(agentSelection);
      }
    }
  }, [role, agentSelection]);

  useEffect(() => {
    if (role === 'Agent'){
      agentQR();
    }
  }, []);

  const agentList = async () => {
    if(role === 'Admin_CS'){
      try{
        let requestData = {
            role : role,
            userId: userId,
        }

        const response = await reportAgentListV3(requestData);
        let result = await response.json();
        
        if (result.status === 'ok') {
            setAgentOptions(result.msg != 'No record.' ? result.data : []);
        }
        else{
            alertData.title = "Failed";
            alertData.msg = result.msg;
            sharedFailAlert(alertData);
        }  
      }
      catch (error) {
          alertData.title = "Error";
          alertData.msg = "Failed to fetch agent list";
          sharedFailAlert(alertData);
      }
    }
  }

  const agentQR = async () => {
    setLoading(true);
    try{
      let requestData = {
          role : role,
          companyId:  role === 'Admin_CS' ? companyId : '',
          secret: role === 'Admin_CS' ? agentSelection : refCode,
          userId: userId,
      }

      const response = await getAgentQR(requestData);
      let result = await response.json();
      
      if (result.status === 'ok') {
        setAgentQRCode(result.msg != 'No record.' ? result.data : []);
        if (result.data?.qrCode) {
          generateQRCode(result.data.qrCode);
        }
      }
      else{
          alertData.title = "Failed";
          alertData.msg = result.msg;
          sharedFailAlert(alertData);
      }  
    }
    catch (error) {
        alertData.title = "Error";
        alertData.msg = "Failed to fetch agent QR";
        sharedFailAlert(alertData);
    }
    finally {
      setLoading(false);
    }
  }

  const generateQRCode = async (text) => {
    setLoading(true);
    try {
      const url = await QRCode.toDataURL(text, {
        errorCorrectionLevel: 'L',
        margin: 0,
        scale: 10,
        width: 4
      });
      setQrDataUrl(url);
    } catch (err) {
      console.error('QR generation error:', err);
    }
    setLoading(false);
  };

  let QRCODE = role === 'Admin_CS' ? agentQRCode?.qrCode : qrCode;
  let REFCODE = role === 'Admin_CS' ? agentQRCode?.refCode : refCode;

  console.log('qrcode: ', QRCODE);
  console.log('refcode: ', REFCODE);

  // const [inputRef] = useQRCode({
  //   text: qrCode,
  //   options: {
  //     level: "L",
  //     margin: 0,
  //     scale: 10,
  //     width: 4,
  //     // color: {
  //     //   dark: "#010599FF",
  //     //   light: "#FFBF60FF",
  //     // },
  //   },
  // });

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess("Copied!");
    } catch (err) {
      setCopySuccess("Failed to copy!");
    }
  };

  const copyLink = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess("Copied!");
    } catch (err) {
      setCopySuccess("Failed to copy!");
    }
  };

  const styles = {
    screenContainer: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      maxWidth: "1200px",
      margin: "0 auto", 
      padding: "0 20px",
      minHeight: "500px",
    },
    leftSection: {
      width: "400px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      paddingTop: "40px",
    },
    rightSection: {
      width: "500px",
      paddingTop: "70px",
    },
    qrCodeWrapper: {
      width: "300px",
      height: "300px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#f5f5f5",
      border: "1px solid #e8e8e8",
      position: "relative",
    },
    qrImage: {
      maxWidth: "100%",
      maxHeight: "100%",
      objectFit: "contain"
    },
    mobileContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      padding: "20px",
      minHeight: "500px",
    },
    mobileQrSection: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      marginBottom: "30px",
    },
    agentSelector: {
      width: "100%",
      maxWidth: "300px",
      marginBottom: "20px",
      marginTop: "20px",
    }
  };

  return (
    <div>
      {isMobile ? (
        <div style={styles.mobileContainer}>
          {isAdminCS && (
            <div style={styles.agentSelector}>
              <Select
                value={agentSelection}
                style={{width: "100%"}}
                optionFilterProp="children"
                ref={reportAgentRef}
                filterOption={(input, option) => {
                  option.children.toLowerCase().includes(input.toLowerCase())
                }}
                placeholder="Please select an agent"
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={(value) => setAgentSelection(value)}
              >
                {agentOptions.map((option) => (
                  <Option key={option.key} value={`${option.refCode}`}>
                    {option.username}
                  </Option>
                ))}
              </Select>
            </div>
          )}
          <div style={styles.mobileQrSection}>
            <div style={styles.qrCodeWrapper}>
              {QRCODE && <img style={styles.qrImage} src={qrDataUrl || QRCODE} alt="QRCode" />}
            </div>
          </div>
          <div style={{width: "100%"}}>
            <Timeline>
            <Timeline.Item color="green">
                <p style={{ fontWeight: "bold", fontSize: 25 }}>Step 1</p>
                {isAdminCS && (
                  <p> - Select an agent to view their QR code</p>
                )}
                <p>
                  - Click on the right hand side of the mouse for save the QR
                  Code.
                </p>
              </Timeline.Item>
              <Timeline.Item color="green">
                <p style={{ fontWeight: "bold", fontSize: 25 }}>Step 2</p>
                <p>Share the QR Code to member.</p>
              </Timeline.Item>
            </Timeline>
          </div>
        </div>
      ) : (
        <div>
          <PageHeader ghost={false}>
            <div style={{ textAlign: "center" }}>
              <div style={{ fontSize: 25 }}>QR Code</div>
            </div>
          </PageHeader>
          <div style={styles.screenContainer}>
            <div style={styles.leftSection}>
            {!isAdminCS && (<div style={{paddingBottom:'40px'}}/>)}
              {isAdminCS && (
                <div style={{...styles.agentSelector, marginLeft: "auto", marginRight: "auto"}}>
                  <Select
                    value={agentSelection}
                    style={{width: "100%"}}
                    optionFilterProp="children"
                    ref={reportAgentRef}
                    filterOption={(input, option) => {
                      option.children.toLowerCase().includes(input.toLowerCase())
                    }}
                    placeholder="Please select an agent"
                    getPopupContainer={(trigger) => trigger.parentNode}
                    onChange={(value) => setAgentSelection(value)}
                  >
                    {agentOptions.map((option) => (
                      <Option key={option.key} value={`${option.refCode}`}>
                        {option.username}
                      </Option>
                    ))}
                  </Select>
                </div>
              )}
              <div style={styles.qrCodeWrapper}>
                {QRCODE && <img style={styles.qrImage} src={qrDataUrl || QRCODE} alt="QRCode" />}
              </div>
            </div>
            <div style={styles.rightSection}>
              <Timeline>
                <Timeline.Item color="green">
                  <p style={{ fontWeight: "bold", fontSize: 25 }}>Step 1</p>
                  {isAdminCS && (
                    <p> - Select an agent to view their QR code</p>
                  )}
                  <p>
                    - Click on the right hand side of the mouse for save the QR
                    Code
                  </p>

                  <p>
                    - Download link:
                  </p>
                  {showCopyButton && (
                    <>
                      <Tag style={{ width: 350 }}>
                        <Button
                          className={"copy"}
                          style={{
                            border: "none",
                            backgroundColor: "transparent",
                          }}
                        >
                          <a href={QRCODE}>{QRCODE}</a>
                        </Button>
                      </Tag>
                    </>
                  )}
                  {showCopyButton && (
                    <>
                      <Tooltip title={!copySuccess ? "Copy" : copySuccess}>
                        <Tag color="blue" style={{ fontSize: 15 }}>
                          <Button
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                            onClick={() => copyLink(QRCODE)}
                          >
                            Copy
                          </Button>
                        </Tag>
                      </Tooltip>
                    </>
                  )}

                  <p />
                  <p>
                    - Press it and copy the referral code:
                  {showCopyButton && (
                    <>
                      <Tooltip title={!copySuccess ? "Copy" : copySuccess}>
                        <Tag color="blue" style={{ marginLeft: 20 }}>
                          <Button
                            className={"copy"}
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                            onClick={() => copyToClipBoard(REFCODE)}
                          >
                            {REFCODE}
                          </Button>
                        </Tag>
                      </Tooltip>
                    </>
                  )}
                  </p>
                </Timeline.Item>
                <Timeline.Item color="green">
                  <p style={{ fontWeight: "bold", fontSize: 25 }}>Step 2</p>
                  <p>Share to member.</p>
                </Timeline.Item>
              </Timeline>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}