import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  Form,
  Table,
  Select,
  Badge,
  PageHeader,
  Typography,
  Input,
  Upload,
  message,
} from "antd";
import {
  EditOutlined,
  SaveOutlined,
  CloseCircleOutlined,
  SearchOutlined,
  QuestionCircleOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  getDepEmailList,
  getEmailAuthUrl,
  addDepEmail,
  removeEmailConfig,
  removeDepEmail,
  getBankMachList,
  getServerIp,
  uploadCredentialFile,
  uploadReceiptV2,
  uploadDepositFile,
} from "../../config/networkConfig";
import { useSelector } from "react-redux";
import Highlighter from "react-highlight-words";
import {
  alertData,
  sharedSuccessAlert,
  sharedErrorAlert,
  sharedFailAlert,
} from "../../common/constants/sharedMsgBox";
import { bindSmsVw } from "../../library/permission";

export default function BindDeposit() {
  const { Paragraph } = Typography;
  const { Text } = Typography;
  const { confirm } = Modal;
  const { Option } = Select;
  const [form] = Form.useForm();
  const modalForm = useRef();
  const [visible, setVisible] = useState(false);
  const { role, companyId, username, userId } = useSelector(
    (state) => state.login
  );
  const [selectedAcctNo, setSelectedAcctNo] = useState("");
  const [selectedAcctName, setSelectedAcctName] = useState("");
  const [selectedBankCode, setSelectedBankCode] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [urlVisible, setUrlVisible] = useState(false);
  const [isDepBankSelected, setIsDepBankSelected] = useState(true);
  const [isFileUploaded, setIsFileUplaoded] = useState(true);
  const [fileList, setFileList] = useState([]);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [editingKey, setEditingKey] = useState("");
  const [tempTableData, setTempTableData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [selectionList, setSelectionList] = useState([]);
  const [tempSelectionList, setTempSelectionList] = useState([]);
  const [serverIp, setServerIp] = useState("");

  const layout = {
    labelCol: { span: 8 },
  };

  useEffect(() => {
    // getEnvayaBankList();
    getDepositBankList();
    // retrieveServerIp();
    // eslint-disable-next-line
  }, []);

  // Set selection List and set table data with bank code
  useEffect(() => {
    // Extract acctNo values from tempTableData
    const existingActiveAcctNo = tempTableData
      .filter((item) => item.status !== 0)
      .map((item) => item.acctNo);
    // get an array of acct numbers

    // Filter out items that already exist in tableData
    // const newData = tempSelectionList
    //   .filter((data) => !existingActiveAcctNo.includes(data.acctNo))
    //   .map((data) => ({
    //     key: data.count,
    //     acctNo: data.acctNo,
    //     acctName: data.acctName,
    //     bankCode: data.bankCode,
    //   }));
    // setSelectionList(newData);

    // Filter out items that already exist in tableData and are from SCB
    const newData = tempSelectionList
      .filter(
        (data) =>
          !existingActiveAcctNo.includes(data.acctNo) && data.bankCode === "SCB"
      )
      .map((data) => ({
        key: data.count,
        acctNo: data.acctNo,
        acctName: data.acctName,
        bankCode: data.bankCode,
      }));
    setSelectionList(newData);

    // Get bank code from tempselectionlist
    const newData2 = tempTableData.map((data) => {
      const selectedItem = tempSelectionList.find(
        (selection) => selection.acctNo === data.acctNo
      );
      const bankCode = selectedItem ? selectedItem.bankCode : ""; // Get bankCode from selectionList
      return {
        key: data.key,
        acctNo: data.bankCode + ` ` + `(` + data.acctNo + `)`,
        acctNoOri: data.acctNo,
        acctName: selectedItem.acctName,
        bankCode: data.bankCode,
        email: data.email,
        createDate: data.createDate,
        createBy: data.createBy,
        // status: data.status,
      };
    });
    console.log(newData2);
    setTableData(newData2);
  }, [tempSelectionList]);

  const columns = [
    {
      ellipsis: true,
      title: "Account No.",
      dataIndex: "acctNo",
      width: "100px",
    },
    {
      ellipsis: true,
      title: "Email",
      dataIndex: "email",
      width: "100px",
    },
    {
      ellipsis: true,
      title: "Date Created",
      dataIndex: "createDate",
      width: "100px",
    },
    {
      ellipsis: true,
      title: "Created By",
      dataIndex: "createBy",
      width: "100px",
    },
    {
      ellipsis: true,
      title: "Action",
      // align: "center",
      dataIndex: "action",
      width: "60px",
      render: (text, record) => {
        return (
          <div>
            <Button
              type="danger"
              disabled={!bindSmsVw(role)}
              onClick={() => {
                handleRemove(record);
              }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        );
      },
    },
  ];

  const handleConfirm = () => {
    console.log(userId, companyId, username);
    let msg = `Are you sure you want to setup Deposit Email for this bank?`;

    confirm({
      className: "confirmationModalStyle",
      title: "Confirmation",
      width: "500px",
      centered: true,
      icon: <QuestionCircleOutlined />,
      content: (
        <Paragraph>
          {msg}
          <ul>
            <li>Account No. : {selectedAcctNo}</li>
            <li>Account Name : {selectedAcctName}</li>
            <li>Bank : {selectedBankCode}</li>
            <li>Email : {email}</li>
          </ul>
        </Paragraph>
      ),
      onOk() {
        handleGetEmailAuthUrl();
      },
      onCancel() {},
    });
  };

  const handleRemove = (record) => {
    let msg = `Are you sure you want to remove Deposit Email for this bank?`;

    confirm({
      className: "confirmationModalStyle",
      title: "Confirmation",
      width: "500px",
      centered: true,
      icon: <QuestionCircleOutlined />,
      content: (
        <Paragraph>
          {msg}
          <ul>
            <li>Account No. : {record.acctNoOri}</li>
            <li>Account Name : {record.acctName}</li>
            <li>Bank : {record.bankCode}</li>
            <li>Email : {record.email}</li>
          </ul>
        </Paragraph>
      ),
      onOk() {
        setTableLoading(true);
        // handleRemoveEnvayaBank(record.acctNoOri, record.acctName, record.bankCode);
        handleRemoveDepositBank(
          record.acctNoOri,
          record.bankCode,
          record.email,
          record.acctName
        );
      },
      onCancel() {},
    });
  };

  const handleCancel = () => {
    setVisible(false);
    setUrlVisible(false);
    setIsDepBankSelected(true);
    setIsFileUplaoded(true);
    setFileList([]);
    setSelectedAcctName("");
    setSelectedAcctNo("");
    setEmail("");
    setPassword("");
    modalForm.current.resetFields();
  };

  //#region Get bank list from deposit_bank_email
  const getDepositBankList = async () => {
    let count = 0;

    setTableLoading(true);
    // List out the banks in deposit_bank_email
    const respDepositBankEmail = await getDepEmailList();
    let resp = await respDepositBankEmail.json();
    if (resp.status === "ok") {
      if (resp.data.length > 0) {
        const newData = resp.data.map((data) => {
          return {
            key: count++,
            acctNo: data.acctNo,
            bankCode: data.bankCode,
            email: data.email,
            createBy: data.createBy,
            createDate: data.createDate,
          };
        });
        console.log(newData);
        setTempTableData(newData);
        // setTableLoading(false);
      } else {
        setTempTableData([]);
      }
    } else {
      // setTableLoading(false);
      alertData.title = "Failed";
      alertData.msg = resp.msg;
      sharedFailAlert(alertData);
    }
    getEnvayaSelectionList();
  };

  const handleGetEmailAuthUrl = async () => {
    setModalLoading(true);
    const requestData = {
      bankAcc: selectedAcctNo,
      bankName: selectedAcctName,
      bankCode: selectedBankCode,
      email: email,
      password: password,
      username: username,
    };

    const respGetEmailAuthUrl = await getEmailAuthUrl(requestData);
    let resp = await respGetEmailAuthUrl.json();
    if (resp.status === "ok") {
      setUrlVisible(true);
      setVisible(false);
      openPopupWindow(resp.msg);
    } else {
      alertData.title = "Failed";
      alertData.msg = resp.msg;
      sharedFailAlert(alertData);
      handleCancel();
    }
    setModalLoading(false);
  };

  const handleAddDepBankEmail = async () => {
    setModalLoading(true);
    const requestData = {
      bankAcc: selectedAcctNo,
      bankName: selectedAcctName,
      bankCode: selectedBankCode,
      email: email,
      password: password,
      username: username,
    };

    const respAddDepBankEmail = await addDepEmail(requestData);
    let resp = await respAddDepBankEmail.json();
    if (resp.status === "ok") {
      alertData.title = "Success";
      alertData.msg = `Deposit Email for ${requestData.bankAcc} (${requestData.bankCode}) is bind successfully.`;
      sharedSuccessAlert(alertData);
      getDepositBankList();
    } else {
      alertData.title = "Failed";
      alertData.msg = resp.msg;
      sharedFailAlert(alertData);
    }
    handleCancel();
    setModalLoading(false);
  };

  const handleCancelAddDepBankEmail = async () => {
    setModalLoading(true);
    const requestData = {
      bankAcc: selectedAcctNo,
      bankName: selectedAcctName,
    };

    const respCancelDepBankEmail = await removeEmailConfig(requestData);
    let resp = await respCancelDepBankEmail.json();
    if (resp.status === "ok") {
      console.log(resp.msg);
      getDepositBankList();
    } else {
      console.log(resp.msg);
    }
    handleCancel();
    setModalLoading(false);
  };

  const handleRemoveDepositBank = async (
    bankAcc,
    bankCode,
    email,
    bankName
  ) => {
    const requestData = {
      bankAcc: bankAcc,
      bankName: bankName,
      bankCode: bankCode,
      email: email,
    };

    console.log(requestData);

    const respRemoveDepEmail = await removeDepEmail(requestData);
    let resp = await respRemoveDepEmail.json();
    if (resp.status === "ok") {
      setTableLoading(false);
      alertData.title = "Success";
      alertData.msg = `Deposit Email for ${bankAcc} (${bankCode}) is removed successfully.`;
      sharedSuccessAlert(alertData);
    } else {
      setTableLoading(false);
      alertData.title = "Failed";
      alertData.msg = `Failed to remove Deposit Email for ${bankAcc} (${bankCode}). Please try again later.`;
      sharedFailAlert(alertData);
    }
    getDepositBankList();
  };

  // const retrieveServerIp = async () => {
  //   let requestData = {};
  //   const result = await getServerIp(requestData);
  //   let resp = await result.json();

  //   if (resp.status == "ok") {
  //     setServerIp(resp.data);
  //   } else {
  //     alertData.title = "Failed";
  //     alertData.msg = "Failed to get server IP.";
  //     sharedFailAlert(alertData);
  //   }
  // };

  // get the deposit banks that havent setup SMS from bank_machine
  const getEnvayaSelectionList = async () => {
    let count = 0;
    const requestData = {
      BankType: "Deposit",
    };

    setTableLoading(true);
    const respEnvayaSelection = await getBankMachList(requestData);
    let resp = await respEnvayaSelection.json();

    if (resp.status === "ok") {
      // setSelectionList(newData);
      if (resp.data.length > 0) {
        const newData = resp.data.map((data) => ({
          key: count++,
          acctNo: data.acctnum,
          acctName: data.acctname,
          bankCode: data.bankcode,
        }));
        setTempSelectionList(newData);
      } else {
        setTempSelectionList([]);
      }
    }
    setTableLoading(false);
  };

  const openPopupWindow = (openUrl) => {
    const width = 800;
    const height = 1000;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;

    window.open(
      openUrl,
      "LoginWindow",
      `width=${width},height=${height},` +
        `resizable=yes,scrollbars=yes,` +
        `top=${top},left=${left}`
    );
  };

  const uploadFile = async () => {
    const formData = new FormData();
    formData.append("fileReceipt", fileList[0]);
    formData.append("bankAcc", selectedAcctNo);
    if (fileList[0] !== undefined) {
      const response = await uploadDepositFile(formData);
      let result = await response.json();
      return result;
    }
  };

  const handleUploadFile = async ({ file, onSuccess, onError }) => {
    const uploadResult = await uploadFile();
    console.log(uploadResult);

    if (uploadResult.status === "ok") {
      onSuccess(uploadResult, file);
      alertData.title = "Success";
      alertData.msg =
        uploadResult.msg + " Please enter email and password to complete the process.";
      sharedSuccessAlert(alertData);
      setIsFileUplaoded(false);
    } else {
      alertData.title = "Failed";
      alertData.msg = uploadResult.msg;
      sharedFailAlert(alertData);
      setIsFileUplaoded(true);
      setFileList([]);
    }
  };

  const fileProps = {
    listType: "file",
    onRemove: () => {
      setFileList([]);
      setIsFileUplaoded(true);
      modalForm.current.setFieldsValue({ upload: undefined }); // Clear form value
      return true;
    },
    fileList: fileList,
    beforeUpload: (file) => {
      const isValidSize = file.size / 1024 / 1024 < 10000;
      if (!isValidSize) {
        // message.error("File must be smaller than 100MB!");
        return Upload.LIST_IGNORE;
      }
      // Replace the existing file
      setFileList([file]);
      return true;
    },
    onChange: (info) => {
      setFileList(info.fileList.slice(-1));

      if (info.file.status === "done") {
        // message.success(`${info.file.name} file uploaded successfully`);
        modalForm.current.setFieldsValue({ upload: info.fileList }); // Update form value
      } else if (info.file.status === "error") {
        // message.error(`${info.file.name} file upload failed.`);
        console.log(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <div style={{ backgroundColor: "#FFFFFF", paddingLeft: 10 }}>
      <Modal
        title="Setup Deposit Email"
        visible={visible}
        footer={null}
        closable={false}
        confirmLoading={modalLoading}
      >
        <Form {...layout} ref={modalForm} layout="vertical">
          <Form.Item
            name={["bank", "selectBankType"]}
            label="Deposit Bank"
            rules={[
              {
                required: true,
                message: "Please select your Deposit Bank!",
                whitespace: true,
              },
            ]}
          >
            <Select
              placeholder="Please Select Bank"
              getPopupContainer={(trigger) => trigger.parentNode}
              onSelect={(value, option) => {
                setSelectedAcctNo(option.key);
                setSelectedAcctName(value);
                setSelectedBankCode(option.bankCode);
                setIsDepBankSelected(false);
              }}
            >
              {selectionList.length > 0 ? (
                selectionList.map((selection) => (
                  <Option
                    key={selection.acctNo}
                    value={selection.acctName}
                    bankCode={selection.bankCode}
                  >
                    {`${selection.bankCode} ( ${selection.acctNo} ) - ${selection.acctName}`}
                  </Option>
                ))
              ) : (
                <Option value="None" disabled>
                  None
                </Option>
              )}
            </Select>
          </Form.Item>

          <Form.Item
            name="upload"
            label="Upload File"
            rules={[
              {
                required: true,
                message: "Please upload a file!",
              },
            ]}
          >
            <Upload
              name="file"
              customRequest={handleUploadFile}
              {...fileProps}
              fileList={fileList}
              disabled={isDepBankSelected}
            >
              <Button icon={<UploadOutlined />} disabled={isDepBankSelected}>
                Click to Upload
              </Button>
            </Upload>
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please enter your email address!",
              },
              {
                type: "email",
                message: "Please enter a valid email address!",
              },
            ]}
          >
            <Input
              value={email}
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              disabled={isFileUploaded}
            />
          </Form.Item>
          <Form.Item
            id="password"
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Please enter your password!",
                whitespace: true,
              },
            ]}
            required={true}
          >
            <Input
              name="new-password"
              autoComplete="new-password"
              value={password}
              placeholder="Password"
              rules={[
                { required: true, message: "Please enter your password" },
              ]}
              onChange={(e) => {
                setPassword(e.target.value.replace(/\s+/g, ""));
              }}
              disabled={isFileUploaded}
            />
          </Form.Item>
          <Form.Item style={{ textAlign: "right" }}>
            <Button
              loading={modalLoading}
              type="primary"
              htmlType="submit"
              style={{ marginRight: 10 }}
              onClick={() => {
                if (
                  selectedAcctName &&
                  selectedAcctNo &&
                  email &&
                  password &&
                  !isFileUploaded
                ) {
                  handleConfirm();
                }
              }}
            >
              Submit
            </Button>
            <Button
              htmlType="button"
              onClick={() => {
                handleCancel();
              }}
              loading={modalLoading}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={null}
        visible={urlVisible}
        footer={null}
        width={650}
        closable={false}
        onCancel={null}
        centered
      >
        <div
          style={{
            width: "100%",
            display: "flexColumn",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <div>
            <h3
              style={{
                color: "#333",
                marginBottom: "10px",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              Please follow these steps to complete the bind deposit email
              process:
            </h3>
            <ol
              style={{
                paddingLeft: "20px",
                color: "#555",
              }}
            >
              <li style={{ marginBottom: "10px" }}>
                In the popup window, log in to your Email account.
              </li>
              <li style={{ marginBottom: "10px" }}>
                After complete authentication, come back to this page and click
                the "Done" button.
              </li>
            </ol>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end", // This aligns content to the right
              alignItems: "center", // Keep vertical alignment centered
            }}
          >
            <Button
              type="primary"
              key="submit"
              loading={modalLoading}
              onClick={handleAddDepBankEmail}
            >
              Done
            </Button>
          </div>

          {/* <Text
            copyable={{
              text: `${gmailUrl}`,
              tooltips: ["Click to copy", "Copied!"],
            }}
            style={{
              width: "calc(100% - 120px)", // Adjust width to make room for button
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              fontSize: "16px",
            }}
            ellipsis={{
              tooltip: true,
            }}
            code
          >
            {gmailUrl}
          </Text> */}
        </div>
      </Modal>

      <PageHeader ghost={false}>
        <div>
          <Button
            type="primary"
            onClick={() => {
              setVisible(true);
              setEditingKey("");
            }}
            disabled={!bindSmsVw(role) || tableLoading}
          >
            Add
          </Button>
        </div>
      </PageHeader>

      <Form form={form} component={false}>
        <Table
          style={{ overflow: "auto", marginLeft: 20 }}
          loading={tableLoading}
          scroll={{ x: "100%", y: null }}
          columns={columns}
          dataSource={tableData}
        />
      </Form>
      <div style={{ padding: 24 }}>
        <p>
          <b>REMARK :</b>
        </p>
        <p>
          <b>
            <span style={{ color: "blue" }}>[1]</span>
          </b>{" "}
          Bind deposit email only applicable for <b>SCB</b> bank at the moment.
        </p>
      </div>
    </div>
  );
}
