import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  message,
  PageHeader,
  Button,
  Input,
  DatePicker,
  Card,
  Badge,
  Row,
  Col,
  Tooltip,
  Modal,
  Form,
  Select,
  Tabs,
  Popconfirm,
} from "antd";
import {
    csMemberUpline,
    csMemberDownlineList,
    csMemberWalletTrxn,
    userDLSummary,
    userProfileBank,
    userBankAll,
    userBankNew,
    userBankDelete,
    csUpdateMember,
    csAvailBankList,
    csMemberGameSummary,
    csMemberGameTrxn,
    userMemberLockV2,
    userMemberActiveV2,
    reportShareholderList
} from "../../../config/networkConfig";
import { useLocation, useHistory }from "react-router-dom";
import Highlighter from "react-highlight-words";
import { FormatMask, FormatPt } from "../../../library/numbers";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import moment from "moment";
import { settings } from "../../../config/appConfig";
import { 
  InfoCircleFilled, 
  SearchOutlined,
  ForkOutlined,
  AccountBookFilled,
  DollarOutlined,
  UserOutlined,
  DeleteOutlined,
  UpCircleOutlined,
  LockOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import { repDownlineVw } from "../../../library/permission";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../../common/constants/sharedMsgBox";

export default function ReportDownline() {
    const { search } = useLocation();
    const  history = useHistory();
    const searchParam = new URLSearchParams(search);
    const userid = searchParam.get('uid');
    const { TextArea } = Input;

    const { companyId , username, role, userId, issuper} = useSelector((state) => state.login);


    const [searchText, setSearchText] = useState("");
    const [tableLoading, setTableLoading] = useState(false);
    const [searchMobile, setSearchMobile] = useState("");
    const [searchDate, setSearchDate]    = useState("");
    const reportMobile = useRef(null);
   
    const [cardTitle, setCardTitle] = useState("Generation Count");

    const [downlineVisible,setDownlineVisible] = useState(false);
    
    const [gen1Count, setGen1Count] = useState(0);
    const [gen1Key, setGen1Key] = useState(0);
    const [gen2Count, setGen2Count] = useState(0);
    const [gen2Key, setGen2Key] = useState(0);
    const [genAllCount, setGenAllCount] = useState(0);

    const [gen1Key1, setGen1Key1] = useState(0);
    const [gen2Key1, setGen2Key1] = useState(0);
    const [gen1Count1, setGen1Count1] = useState(0);
    const [gen2Count1, setGen2Count1] = useState(0);
    const [genAllCount1, setGenAllCount1] = useState(0);

    //Lock Button
    const [selected, setSelected] = useState(true);
    const [reason, setReason] = useState("");
    const [activeKey, setActiveKey] = useState("");
    const [visible, setVisible] = useState(false);

    //Trxn Summary
    const [trxnSummVisible,setTrxnSummVisible] = useState(false);
    const [totalDepAmount,setTotalDepAmount]   = useState(0);
    const [totalWitAmount,setTotalWitAmount]   = useState(0);
    const [totalCommAmount,setTotalCommAmount]   = useState(0);

    //Turnover Transaction
    const [csGameBetAmt,setCSGameBetAmt] = useState(0);
    const [csGameUser, setCSGameUser] = useState("");
    const [csGameWinloss,setCSGameWinloss] = useState(0);
    const [csGameTurnover,setCSGameTurnover] = useState(0);

    //Wallet Transaction 
    const [viewTrx,setViewTrx] = useState("");
    const [puppetVisible, setPuppetVisible]= useState(false);
    const [csSearchMemberId,setCSSearchMemberId] = useState("");
    const [csMemberMobile,setCSMemberMobile] = useState("");
    const [csMemberWalletDt,setCSMemberWalletDt] = useState("");
    const [csMemberWalletBal, setCSMemberWalletBal] = useState(0);
    const [csMemberWalletComm,setCSMemberWalletComm] = useState(0);
    const [filteredPptTrxn,setFilterPptTrxn]= useState([]);
    const [trxDateFrom, setTrxDateFrom] = useState("");
    const [trxDateTo, setTrxDateTo] = useState("");
    const reportDateFrom = useRef(null);
    const reportDateTo = useRef(null);
    const [date, setDate] = useState("");
    const [trxLoading, setTrxLoading] = useState(false);

    //Customer Details Dialog
    const [viewMemberTxt,setViewMemberText] = useState("View Member");
    const [viewMemberDlgTitle,setViewMemberDlgTitle] = useState("View Member");
    const [viewMemberDlgOK,setViewMemberDlgOK] = useState("Save");
    const [editMemberEnable, setEditMemberEnable] = useState(false);
    const [userBankData, setUserBankData] = useState([]);
    const [trxBankLoading, setTrxBankLoading] = useState(false);
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const { Option } = Select;
    const {TabPane} = Tabs;
    const [phoneNum, setPhoneNum] = useState("");
    const [countryCode, setCountryCode] = useState("60");
    const [pin,setPin] = useState("");
    const [bankcode, setBankCode] = useState("");  
    const [acctnum, setAcctNum] = useState("");  
    const [acctname,setAcctname] = useState("");
    const [referCode,setReferCode] = useState("");
    const [lineId, setLineId] = useState("");
    const [gender, setGender] = useState("");
    const [csAvaBankList,  setCSAvaBankListOptions] = useState([]);
    const [editMemberModal,     setEditMemberModal] = useState(false);
    const [editMemberDisable, setEditMemberDisable] = useState(false);
    //const [btnEnabled,setBtnEnabled] = useState(false);
    const [addMemberModal,          setAddMemberModal] = useState(false);
    const [prevbankcode,              setPrevBankCode] = useState("");
    const [prevAcctnum,                setPrevAcctNum] = useState("");
    const [memberUserId,              setMemberUserId] = useState("");
    const [memberGameUser,          setMemberGameUser] = useState("");
    const [memberLastLogin,        setMemberLastLogin] = useState("");
    const [memberLastDeposit,    setMemberLastDeposit] = useState("");
    const [memberTurnover,          setMemberTurnover] = useState("");
    const [memberReqTurnover,    setMemberReqTurnover] = useState("");

    const [loadings, setLoadings] = useState([]);
    const [referEditDisable,setReferEditDisable] = useState(true);

    //Upline List
    const [uplineData,         setUplineData]   = useState("");
    const [ulTableLoading, setUlTableLoading]   = useState(false);
    
    //Downline List
    const [downlineData, setDownlineData] = useState("");
    const [downlinePageSize, setDownlinePageSize]  = useState(10);
    const [downlinePageNum,   setDownlinePageNum]  = useState(1);
    const [downlineTotal,       setDownlineTotal]  = useState(10);
    const [dlTableLoading,      SetDLTableLoading] = useState(false);

    //Shareholder List
    const [shareholderSelection, setShareholderSelection] = useState(null);
    const [shareholder, setShareholder] = useState(null);
    const reportShareholderRef = useRef(null);
    const [shareholderOptions, setShareholderOptions] = useState([]);
    const [isAdminCS, setIsAdminCS] = useState(false);

    //Status Filter
    const [status, setStatus] = useState("All");

    useEffect(() => {
      shareholderList();
    }, [status]);

    useEffect(() => {
      if (role === "Admin_CS") {
        setIsAdminCS(true);
      } else {
        setIsAdminCS(false);
      }
    }, [role]);

    useEffect(()=> {
      setShareholderSelection(shareholderSelection);
      setShareholder(shareholder);
    },[shareholderSelection, shareholder]);

    useEffect(() => {
      const searchParams = new URLSearchParams(window.location.search);
      const uidFromUrl = searchParams.get('uid');
      const shareholderFromUrl = searchParams.get('shareholder');
      const savedShareholder = localStorage.getItem('shareholderSelection');
  
      if (role === 'Admin_CS') {
          // Restore shareholder selection from URL or localStorage
          if (shareholderFromUrl) {
              setShareholderSelection(shareholderFromUrl);
          } else if (savedShareholder) {
              setShareholderSelection(savedShareholder);
              localStorage.removeItem('shareholderSelection');
          }
      }
  
      // If we have a mobile number in URL, trigger search
      if (uidFromUrl) {
          setSearchMobile(uidFromUrl);
          onSearchDownline(uidFromUrl);
      }
    }, []);
    
    useEffect(() => {
      availBankList();
    }, []);

    useEffect(()=> {
       setSearchMobile(userid);
       onSearchDownline(userid);
    },[userid]);

    useEffect(()=> {
      setDownlinePageNum(downlinePageNum);
      setDownlinePageSize(downlinePageSize);
      setDownlineTotal(downlineTotal);
    },[downlinePageNum, downlinePageSize, downlineTotal])

    // setDownlinePageNum(pagination.current);
    // setDownlinePageSize(pagination.pageSize);

    useEffect(()=> {
      setCSSearchMemberId(csSearchMemberId)
    },[csSearchMemberId])

    useEffect(()=> {
      setViewMemberText(issuper? 'Edit Member':'View Member');
      setViewMemberDlgTitle(issuper?'Edit Member':'View Member');
      setViewMemberDlgOK(issuper?'Save':'Close');
      setEditMemberEnable(issuper? true : false);
    },[issuper])

    ////#region Components
    const shareholderList = async () => {
      try{
          let requestData = {
              role : role,
              userId : userId
          }
  
          const response = await reportShareholderList(requestData);
          let result = await response.json();
          
          if (result.status === 'ok') {
              setShareholderOptions(result.msg != 'No record.' ? result.data : []);
          }
          else{
              alertData.title = "Failed";
              alertData.msg = result.msg;
              sharedFailAlert(alertData);
          }  
      }
      catch (error) {
          // console.log('Error fetching agent list:', error);
          alertData.title = "Error";
          alertData.msg = "Failed to fetch shareholder list";
          sharedFailAlert(alertData);
      }
    }

    const onEditMemberForm = () => {

      return (
        <Form form={form} 
              layout="vertical" 
              name="editMemberform"
        >
          <Form.Item
            label="Phone Number"
            required={true}
          >
            <Input.Group compact>
              {
                editMemberEnable ?
                    (
                      <>
                        <Select value={countryCode}
                            onChange={selectCountryCode}>
                              <Option value="66">+66</Option>
                              <Option value="60">+60</Option>
                            </Select>
                            <Input style={styles.phoneNumStyle} defaultValue="" placeholder="phone number" value={phoneNum}
                              onChange={(e) => {
                                setPhoneNum(e.target.value.replace(/\D|^0/g, ""));
                              }}
                            />
                      </>
                    ):
                    (
                      <span>
                      {countryCode}{phoneNum}
                      </span>
                    )
  
              }
            </Input.Group>
          </Form.Item>
          <Form.Item
            id="pin"
            label="PIN"
            required ={true}
          >
            {
              editMemberEnable ?
                (
                  <>
                    <Input
                      value={pin}
                      style={styles.formItem2}
                      placeholder="PIN"
                      maxLength={6}
                      rules={[{required:true, message:'Please input your PIN'}]}
                      onChange={(e) => {
                          setPin(e.target.value.replace(/\D|^0/g, ""));
                      }}
                    />
                  </>
                ):
                (
                  <span>
                    {pin}
                  </span>
                )
            }
          </Form.Item>
          <Form.Item>
          </Form.Item>
          <Row>
              <Col span={10}>
                  <Form.Item
                    id="gameUser"
                    label="Game User"     
                    >
                    <span>{memberGameUser}</span>
                  </Form.Item>
              </Col>
              <Col span={10}>

              </Col>
          </Row> 
          <Row>
              <Col span={10}>
                  <Form.Item
                    id="turnover"
                    label="Turnover"     
                    >
                    <span>{memberTurnover}</span>
                  </Form.Item>
              </Col>
              <Col span={10}>
                  <Form.Item
                  id="reqTurnover"
                  label="Required Turnover"
                  >
                  <span>{memberReqTurnover}</span>
                </Form.Item>
              </Col>
          </Row> 
         
          <Form.Item
            id="bankCode"
            label=""
          > 
            <Tabs defaultActiveKey="1" onChange={onChangeBankTab}>
              <TabPane tab="Bank Account(s)" key="1">
                <Table dataSource={userBankData} loading={trxBankLoading} columns={bankColumns} pagination={false}/>
              </TabPane>
              {
                editMemberEnable?
                  (<TabPane tab="Add Bank" disabled={!editMemberEnable} key="2" style={styles.addBankFormStyle}>
                      <Form form={form2} style={styles.addBankTabStyle}>
                        <Form.Item 
                            label="Select Bank"
                          >
                            <Select
                            style={styles.formItem}
                            showSearch
                            value={bankcode}
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                              return option.children.includes(input);
                            }}
                            placeholder="Please select a bank"
                            getPopupContainer={(trigger) => trigger.parentNode}
                            onChange={(value, event) => {
                              setBankCode(value);
                              //Search Bank Acc Name
                              for (var i = 0; i < csAvaBankList.length; i++) {
                                if (csAvaBankList[i].bankAcc === value) {
                                  setBankCode(csAvaBankList[i].bankCode);
                                  form.setFieldsValue({
                                    bankcode: csAvaBankList[i].bankCode,
                                  });
                                }
                              }
                            }}
                          >
                            {csAvaBankList.map((option) => {
                              return (
                                <Option key={option.bankCode} value={option.bankCode}>
                                  {option.bankName} 
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                        <Form.Item
                            label="Account Number"
                          >
                            <Input
                              value={acctnum}
                              style={styles.formItem}
                              placeholder="Enter Bank Account Number"
                              onChange={onChangeAcctNum}
                            />
                          </Form.Item>
                          <Form.Item
                            id="bankAccountName"
                            label="Account Name"
                          >
                            <Input
                              value={acctname}
                              style={styles.formItem}
                              placeholder="Enter Bank Account Name"
                              onChange={(e) => {
                                  setAcctname(e.target.value);
                              }}
                              />
                          </Form.Item> 
                          <Form.Item>
                          <Button 
                              align="right"
                              type="primary"
                              onClick = {()=> {
                                onAddUserBank();
                              }}
                              >
                                  Save
                              </Button>
                          </Form.Item>         
                      </Form>
                    </TabPane>
                  ):<></>
              }
           </Tabs>
          </Form.Item>
          <Form.Item
            id="referralCode"
            label="Upline Referral Code"
          >
            <span>{referCode}</span>
            {/* <Input
              value={referCode}
              style={styles.formItem}
              placeholder="Upline Referral Code"
              onChange={(e) => {
                setReferCode(e.target.value);
              }}
              disabled={referEditDisable}
              /> */}
          </Form.Item>
          <Form.Item
            id="lineId"
            label="Line Id"
          >
            {
              editMemberEnable?
              (
                <Input
                value={lineId}
                style={styles.formItem2}
                placeholder="Line Id"
                onChange={(e) => {
                    setLineId(e.target.value.replace(/\D|^0/g, ""));
                }}
                />
              ):
              (
                <span>{lineId}</span>
              )
            }
          </Form.Item>
          <Row>
              <Col span={10}>
                  <Form.Item
                    id="gender"
                    label="Gender"     
                    >
                    {
                      editMemberEnable ?
                      (
                        <Select
                          dropdownStyle={{ position: "fixed" }}
                          value={gender}
                          onChange = {(gender)=> {
                              setGender(gender)
                          }}
                          style={styles.fullWidth}
                        >
                          <Option value="M">MALE</Option>
                          <Option value="F">FEMALE</Option>
                          <Option value="O">OTHER</Option>
                        </Select>
                      ):
                      (
                        <span>{convertGender(gender)}</span>
                      )
                    }
                  </Form.Item>
              </Col>
              <Col span={10}>
                  <Form.Item
                  id="dob"
                  label="Date of Birth"
                  pre
                    >
                      {
                        editMemberEnable ?
                        (
                          <DatePicker
                            key="1"
                            id="datePicker"
                            style={styles.fullWidth}
                            onChange={datePicker}
                            value={ date !== "" ? moment(date) : undefined}
                            disabledDate={(current)=> {
                              return current > moment().endOf('day');
                            }}
                          /> 
                        )
                        :
                        (
                          <span>{date}</span>
                        )
                      }
                </Form.Item>
              </Col>
          </Row> 
         
        </Form>
      );
    };

    const BtnViewUpline = (props) => {

      return(
        <Tooltip title="View Upline">
          <Button 
          type="primary" style={{...styles.refreshBtn}} shape="circle" icon={<UpCircleOutlined />} 
            onClick={() => {
              getUpline(props.uplineMobile);
          }}
          ></Button>
        </Tooltip>
      )

  }
    
    const BtnViewTurnover = (props)=> {

      return (
        <Tooltip title="View Turnover">
          <Button type="primary" shape="circle" icon={<DollarOutlined />} 
              onClick={()=>{
                // clearWalletSummary();
                // ViewGameDetails();
                clearWalletSummary();
                ViewGameTrxn(props.memberUserId);
                setCSMemberMobile(props.mobile);
                setCSSearchMemberId(props.memberUserId);
              }
            }
            style={{...styles.refreshBtn}}
          />
        </Tooltip>  
      )
    }
  
    const BtnEditMember = (props)=> {
      return (
        <Tooltip title={viewMemberTxt}>
          <Button type="primary" shape="circle" icon={<UserOutlined />} 
              onClick={() => {
                   handleMember(props);
              }}
            style={{...styles.refreshBtn}}
          /> 
        </Tooltip>
      )
      //Previously Button text "Edit"
    }

    const BtnLockMember = (props) => {
      return (
          <Tooltip title="Lock Member">
            <Button type="primary" shape="circle" icon={<LockOutlined />} 
                onClick={() => {
                    handleLock(props);
                }}
                style={styles.lockMemberBtn} 
              />
          </Tooltip>
      )
    }

    const BtnUnLockMember = (props) => {
      return (
          <Tooltip title="Unlock Member">
            <Button type="primary" shape="circle" icon={<UnlockOutlined />} 
                onClick={() => {
                    handleUnlock(props, 2);
                }}
                style={styles.unlockMemberBtn} 
              />
          </Tooltip>
      )
    }

    // const BtnViewTrxnSummary = (props)=> {
    //   return (
    //       <Tooltip title="View Transaction Summary">
    //         <Button type="primary" shape="circle" icon={<AccountBookFilled/>}
    //             onClick={()=> {
    //                 getTotalTrxnSumm(props);                   
    //             }}
    //             style={{...styles.refreshBtn,...styles.rotate180Deg}}
    //           />
    //       </Tooltip>
    //     )
    // }

    const BtnViewDLSummary = (props)=> {
      return (
          <Tooltip title="View Downline Summary">
            <Button type="primary" shape="circle" icon={<ForkOutlined />}
                onClick={()=> {
                    getDLSummary(props.memberUserId);
                    
                }}
                style={{...styles.refreshBtn,...styles.rotate180Deg}}
              />
          </Tooltip>
        )
    }

    const BtnViewTrxn = (props)=> {

      return (
          <Tooltip title="View Transaction Details">
            <Button type="primary" shape="circle" icon={<InfoCircleFilled />} 
              onClick={()=>{
                  clearWalletSummary();
                  ViewWalletTrxn(props.memberUserId);
                  setCSMemberMobile(props.mobile);
                  setCSSearchMemberId(props.memberUserId);
                }
              }
              //loading={loadings[3]}
              style={{ display: "block", ...styles.refreshBtn }}
            />
         </Tooltip> 
      )
    }
    ////#endregion

    const handleDLTableChange = async (pagination, filters, sorter) => {

      // console.log(`pagination.current >> ${JSON.stringify(pagination)}`);

      await getDownlineDataV2(userid, pagination.current, pagination.pageSize);

      await setDownlineTotal(downlineTotal);

    }

    const handleDLTableSizeChange = ()=> {

    }
    

    const loadingTrue = (index) => {
      const newLoadings = [...loadings];
      newLoadings[index] = true;
      setLoadings(newLoadings);
    };
  
    const loadingFalse = (index) => {
      const newLoadings = [...loadings];
      newLoadings[index] = false;
      setLoadings(newLoadings);
    };

    const handleReasonOk = (index) => {
      if (reason === "") {
        alertData.title = "Field Required";
        alertData.msg = "Please fill in Lock Reason !";
        sharedErrorAlert(alertData);
        return;
      }

      let requestData = {
        userid: memberUserId,
        reason: reason,
        csUserid: role === 'Admin_CS' ? shareholderSelection : userId,
      }

      if (selected) {

        loadingTrue(index);
        userMemberLockV2(requestData)
          .then((response) => response.json())
          .then((json) => {
            if (json.status === "ok") {

              setReason("");
              setVisible(false);
  
              // alertData.title = "Success";
              // alertData.msg = json.msg;
              // sharedSuccessAlert(alertData);
              loadingFalse(index);
              
              window.location.reload();
            }
            else {
              loadingTrue(index);
              alertData.title = "Failed";
              alertData.msg = json.msg;
              sharedFailAlert(alertData);
            }
          })
          .catch((error) => {
            // console.log("error: ", error);
            alertData.title = "Error";
            alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
            sharedErrorAlert(alertData);
          });
      } else {
        // loadingTrue(index);
        // userMemberInactiveV2({ userid: memberUserId, reason: reason, csUserid: userId })
        //   .then((response) => response.json())
        //   .then((json) => {
        //     if (json.status === "ok") {
        //       var newData = [...originData];
        //       newData[activeKey].status = "Inactive";
        //       newData[activeKey].action = "Active";
  
        //       setOriginData(newData);
        //       setVisible(false);
        //       message.success(json.msg);
        //       loadingFalse(index);
        //     } else {
        //       message.error(json.msg);
        //       loadingTrue(index);
        //     }
        //   })
        //   .catch((error) => {
        //     console.log("error: ", error);
        //     message.error(
        //       "An unexpected error occurred. Contact your system administrator "
        //     );
        //   });
      }
    };
  
    const handleReasonCancel = () => {
      setVisible(false);
    };

    const handleUnlock = (record, index) => {
      loadingTrue(index);

      let requestData = {
        userid   :record.memberUserId,
        csUserid : role === 'Admin_CS' ? shareholderSelection : userId,
     }

     //console.log(`handleUnlock requestData >> ${JSON.stringify(requestData)}`);

      userMemberActiveV2(requestData)
        .then((response) => response.json())
        .then((json) => {
          if (json.status === "ok") {
            alertData.title = "Success";
            alertData.msg = json.msg;
            sharedSuccessAlert(alertData);
            loadingFalse(index);
            window.location.reload();
          } else {
            alertData.title = "Failed";
            alertData.msg = json.msg;
            sharedFailAlert(alertData);
            loadingTrue(index);
          }
        })
        .catch((error) => {
          // console.log("error: ", error);
          alertData.title = "Error";
          alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
          sharedErrorAlert(alertData);
        });
    };

    const handleLock = (record) => {
      setSelected(true);
      setVisible(true);
      setMemberUserId(record.memberUserId);
      setActiveKey(record.key);
      setReason("");
    };

    const getUpline = (uplineMobile)=> {
      onSearchDownline(uplineMobile);
      setPageURL(uplineMobile);
    }
    
    const availBankList = async () => {

      csAvailBankList({companyId: companyId})
        .then((response) => response.json())
        .then((json) => {
          if (json.status === "ok") {
            setCSAvaBankListOptions(json.data);
          }
      })
      .catch((error) => {
        // console.log("error: ", error);
        alertData.title = "Error";
        alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
        sharedErrorAlert(alertData);
      });
    };

    const updateMemberInfo = () => {
      return csUpdateMember({
        mobile: countryCode + phoneNum,
        compid: companyId,
        pin:pin,
        referCode: referCode,
        bankcode : bankcode,
        acctname : acctname,
        acctnum: acctnum,
        lineid : lineId,
        dob : date,
        gender : gender,
        updatedby : username,
        userid : memberUserId, 
        prevbankcode : prevbankcode, 
        prevacctnum  : prevAcctnum,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.status === "ok") {
              //memberList();
              //findMobileNumber();
              clearAllFields(); 
              setEditMemberModal(false);
              alertData.title = "Success";
              alertData.msg = json.msg;
              sharedSuccessAlert(alertData);
          } else { 
              alertData.title = "Failed";
              alertData.msg = json.msg;
              sharedFailAlert(alertData);
          };
        });
    };

    const clearAllFields = () => {
      setAddMemberModal(false);
      setPhoneNum("");
      setPin("");
      setReferCode("")
      setBankCode("")
      setAcctname("")
      setAcctNum("")
      setLineId("")
      setGender("");
      setDate("")
      setPrevBankCode("");
      setPrevAcctNum("");
    }
    
    const datePicker = (date, dateString) => {
      setDate(dateString);
    };

    const clearBankCode = () => {
      setBankCode("");
    }

    const getCustBankDetails = async (memberUserId) => {
      let respUserBank = await userBankAll({userid:memberUserId});
      let respBankCon = await respUserBank.json();
  
      setTrxBankLoading(true);
  
      if (respBankCon.status ==="ok" ) {
        if (Object.keys(respBankCon.data).length > 0 ) {      
          
          let count = 0;
  
          let newBankData = respBankCon.data.map((obj) => {
                return {
                  key: count++,
                  userid  : memberUserId,
                  bankcode: obj.bankcode,
                  bank : obj.bank,
                  acctnum : obj.acctnum,
                  acctname : obj.acctname,
                  status : obj.status, 
                  actionStatus : 
                    obj.status == 5 || obj.status == 7
                    ? "Pending"
                    : obj.status == 1
                    ? "Active"
                    : obj.status == 9
                    ? "Deleted"
                    : "-",
                };
              });
  
          newBankData = sortBankData(newBankData);
  
          setUserBankData(newBankData)
        } 
  
        setTrxBankLoading(false);
      }
    }

    const onAddUserBank = async () => {

      if (bankcode == ""){
        alertData.title = "Field Required";
        alertData.msg = "Please select Customer Bank Account !";
        sharedErrorAlert(alertData);
        return;
      }

      if (acctnum == ""){
        alertData.title = "Field Required";
        alertData.msg = "Please fill in Account Number !";
        sharedErrorAlert(alertData);
        return;
      }

      if (acctname == ""){
        alertData.title = "Field Required";
        alertData.msg = "Please fill in Account Name !";
        sharedErrorAlert(alertData);
        return;
      }

      let requestData = {
        userid : memberUserId,
        bankcode : bankcode,
        acctname : acctname,
        acctnum : acctnum,
        createby : username,
        role : role,
      }
  
      const result = await userBankNew(requestData);
      let resp = await result.json();
      if (resp.status === "ok") {
          alertData.title = "Success";
          alertData.msg = resp.msg;
          sharedSuccessAlert(alertData);
          document.getElementById("rc-tabs-0-tab-1").click();
          getCustBankDetails(requestData.userid);
        }
      else{
          alertData.title = "Failed";
          alertData.msg = resp.msg;
          sharedFailAlert(alertData);  
      }
    }
  
    const onDeleteBankAcc = async (key) => {
  
      const dataSource = [...userBankData];
      const record = dataSource.filter((item)=> item.key === key)[0];
  
      let requestData = {
        userid: record.userid,
        bankcode : record. bankcode, 
        acctname : record.acctname,
        acctnum  : record.acctnum,
        updatedby  : username,
      }
  
      const result = await userBankDelete(requestData);
      let resp = await result.json();
      if (resp.status ==="ok") {
          //loadingFalse(index);
          //setNewVisible(false);
          alertData.title = "Success";
          alertData.msg = resp.msg;
          sharedSuccessAlert(alertData);
          getCustBankDetails(requestData.userid);
      }else{
          //loadingFalse(index);
          alertData.title = "Failed";
          alertData.msg = resp.msg;
          sharedFailAlert(alertData);
      }
    }

    const convertGender = (gender)=> {
      let gen = gender.toLowerCase();
      
      switch (gen) {
         case "m":
             return "Male";
         case "f":
             return "Female";
         case "o":
             return "Other";
         default:
             return "N/A";
      }
   }

    const onChangeAcctNum  = (e) => {
      setAcctNum(e.target.value.replace(/\D|^/g, ""));
    }

    const onChangeBankTab = (key)=> {
      if (key == 2) {
        setBankCode("");
        setAcctNum("");
        setAcctname("")
      }
    }

    const selectCountryCode = (value) => {
      setCountryCode(value);
    };

    const sortBankData = (bankData) => {

      let fil = [...bankData].sort((x, y) =>
      x.actionStatus.toLowerCase().includes('active') && y.actionStatus.toLowerCase().includes('active')
        ? 0
        : x.actionStatus.toLowerCase().includes('active')
        ? -1
        : x.actionStatus.toLowerCase().includes('deleted') && y.actionStatus.toLowerCase().includes('deleted')
        ? -2
        : 1,
      );
  
      return fil;
    } 

    const handleMember = async (props) => {

      let newRecord = JSON.parse(JSON.stringify(props));

      //console.log(`handleMember >> ${JSON.stringify(newRecord)}`);

      const response = await userProfileBank({userid:newRecord.memberUserId});
  
      let result = await response.json();
      if (result.status === "ok") {
        if (Object.keys(result.data).length > 0) {
          let userProfile = result.data;
          setMemberUserId(userProfile.userid);
          setCountryCode(userProfile.mobile.substring(0, 2));
          setPhoneNum(userProfile.mobile.substring(2, newRecord.mobile.length));
          setPin(userProfile.pin);
          setBankCode(userProfile.bankcode);
          setAcctNum(userProfile.acctnum);
          setAcctname(userProfile.acctname);
          setReferCode(userProfile.uplineReferCode);
          setLineId(userProfile.lineid);
          setGender(userProfile.gender);
          setDate(userProfile.dob)
          setReferEditDisable(userProfile.referCodeDisable);
          setPrevBankCode(userProfile.bankcode);
          setPrevAcctNum(userProfile.acctnum);
          setEditMemberModal(true);
          setMemberGameUser(newRecord.gameuser);
          // setMemberLastLogin(newRecord.lastLogin);
          // setMemberLastDeposit(newRecord.lastDeposit);
          setMemberTurnover(newRecord.turnover);
          setMemberReqTurnover(newRecord.reqTurnover);
        }
        else {
          alertData.title = "Failed";
          alertData.msg = result.msg;
          sharedFailAlert(alertData);
        }
      }

      setEditMemberModal(true);

      let respUserBank = await userBankAll({userid:newRecord.memberUserId});
      let respBankCon = await respUserBank.json();
  
      setTrxBankLoading(true);
  
      if (respBankCon.status ==="ok" ) {
        if (Object.keys(respBankCon.data).length > 0 ) {      
          
          let count = 0;
  
          let newBankData = respBankCon.data.map((obj) => {
                return {
                  key: count++,
                  userid  : newRecord.userId,
                  bankcode: obj.bankcode,
                  bank : obj.bank,
                  acctnum : obj.acctnum,
                  acctname : obj.acctname,
                  status : obj.status, 
                  actionStatus : 
                    obj.status == 5 || obj.status == 7
                    ? "Pending"
                    : obj.status == 1
                    ? "Active"
                    : obj.status == 9
                    ? "Deleted"
                    : "-",
                };
              });
  
              newBankData = sortBankData(newBankData);
      
              setUserBankData(newBankData)
  
        } 
  
        setTrxBankLoading(false);
      }
    };

    const onCancelTrxn = ()=> {
      setTotalDepAmount(0);
      setTotalWitAmount(0);
      setTotalCommAmount(0);
    }

    const onCancelDownline = ()=> {
        setGen1Count1(0);
        setGen2Count1(0);
        setGenAllCount1(0);
    }

    const getTotalTrxnSumm = (props)=> {
      setTotalDepAmount(props.totalDepAmt);
      setTotalWitAmount(props.totalWitAmt);
      setTotalCommAmount(props.totalCommAmt)
      setTrxnSummVisible(true);
    }

    const getDLSummary = async (csMemberUserId) => {
      let countSumm = 0 , countUpline = 0 ;

      let requestData = {
        userid : csMemberUserId,
      }

      let respTrxn = await userDLSummary(requestData);
      let resTrxn = await respTrxn.json();

      if (resTrxn.status === "ok") {

        if (Object.keys(resTrxn.data).length > 0 ) {
  
          //Get Summary 
          let summary = resTrxn.data.summary;
          let total = resTrxn.data.total ;
          const summaryData = Object.keys(summary).length 
                    ? summary.map((obj) => {
                      return {
                          key : countSumm++,
                          dl1Count : obj.dl1Count,
                          dl2Count: obj.dl2Count,
                      }
                    }): [];
          
          //console.log(`summaryData >> ${JSON.stringify(summaryData)}`);
          setGen1Count1(summaryData[0].dl1Count?? 0 );
          setGen2Count1(summaryData[0].dl2Count?? 0);
          //const generation = summaryData.map(item => item.generation);

          // let keyCount = 0 ;
          // for (var gen of summaryData) {
          //   if (keyCount == 0) {setGen1Count1(gen.dlCount); }
          //   if (keyCount == 1) {setGen2Count1(gen.dlCount); }
          //   keyCount+=1;
          // }

          // const totalData = Object.keys(total).length 
          //         ? total.map((obj) => {
          //           return {
          //               totalDlCnt : obj.totalDlCnt,
          //           }
          //         }): [];

          //setGenAllCount1(totalData.length > 0? totalData[0].totalDlCnt : 0 );
        }
      }

      setDownlineVisible(true);
    }


    const searchTrxn = async () => {

      if (!trxDateFrom) {
        reportDateFrom.current.focus();
        alertData.title = "Field Required";
        alertData.msg = "Please select Date From !";
        sharedErrorAlert(alertData);
        return;
      }
  
      if (!trxDateTo) {
        reportDateTo.current.focus();
        alertData.title = "Field Required";
        alertData.msg = "Please select Date To !";
        sharedErrorAlert(alertData);
        return;
      }
  
      if (viewTrx == "trxn") {        
        ViewWalletTrxn(csSearchMemberId);
      }
      else{
        ViewGameTrxn(csSearchMemberId);
      }
    }

    const clearWalletSummary = async ()=> {
      setPuppetVisible(false);
      setCSMemberWalletDt("");
      setCSMemberWalletBal("");
      setCSMemberWalletComm("");
      setTrxDateFrom("");
      setTrxDateTo("");
  
      // setCSGameUser("");
      // setCSGameBetAmt("");
      // setCSGameWinloss("");
      // setCSGameTurnover("");
  
    }
    const trxDatePicker = (date, dateString) => {
      setTrxDateFrom(date);
    };
  
    const trxDatePicker2 = (date, dateString) => {
      setTrxDateTo(date);
    };

    const handleSearch = (selectedKeys, confirm) => {
      confirm();
      setSearchText(selectedKeys[0]);
    };
  
    const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText("");
      setShareholderSelection(null);
    };
  
    let searchInput = null;
  
    const getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={(node) => {
              searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : false,
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) setTimeout(() => searchInput.select());
      },
      render: (text) =>
        text ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) : (
          false
        ),
    });
  
    const ViewWalletTrxn = async (csMemberUserId) => {
      setViewTrx("trxn");
  
      setPuppetVisible(true);  
      setTrxLoading(true);
      setFilterPptTrxn([]); 
  
      let startDate = await trxDateFrom == "" || trxDateFrom == null ? "": trxDateFrom.format("YYYY-MM-DD");
      let endDate = await trxDateTo == "" || trxDateTo == null ? "": trxDateTo.format("YYYY-MM-DD");
  
      let requestData = {
        userid : csMemberUserId,
        datefrom : startDate, 
        dateto : endDate,
      }
  
      let respTrxn = await csMemberWalletTrxn(requestData);
      let resTrxn = await respTrxn.json();

      if (resTrxn.status === "ok") {
  
        if (resTrxn.data.detail && resTrxn.data.detail.length) {
          setFilterPptTrxn(resTrxn.data.detail); 
  
          let dates = resTrxn.data.detail.map(item => moment(item.date)), maxDate = moment.max(dates) , minDate = moment.min(dates);
 
          setTrxDateFrom(minDate);
          setTrxDateTo(maxDate);
        }
  
        if (resTrxn.data.summary && resTrxn.data.summary.length) {
            let walletSumm = resTrxn.data.summary[0];
            setCSMemberWalletDt(walletSumm.Dt);
            setCSMemberWalletBal(`${FormatPt(walletSumm.Balance)}`);
            setCSMemberWalletComm(`${FormatPt(walletSumm.Comm)}`);
        }else{
          setCSMemberWalletDt("N/A");
          setCSMemberWalletBal("N/A");
          setCSMemberWalletComm("N/A");
        }
      }else {
        setFilterPptTrxn([]);
      }
  
      setTrxLoading(false);
    }
    
    const ViewGameTrxn = async (csMemberUserId) => {

      setViewTrx("turnover");
  
      setPuppetVisible(true);  
      setTrxLoading(true);
  
      let startDate = await trxDateFrom == "" || trxDateFrom == null ? "": trxDateFrom.format("YYYY-MM-DD");
      let endDate = await trxDateTo == "" || trxDateTo == null ? "": trxDateTo.format("YYYY-MM-DD");
  
      const response = await csMemberGameSummary({userid:csMemberUserId});
      const respTrxn = await csMemberGameTrxn({userid:csMemberUserId, datefrom: startDate, dateto: endDate});
    
      let result = await response.json();
      let resTrxn = await respTrxn.json();
  
      if (result.status === "ok") {
         if (result.data && result.data.length) {
           let walletSumm = result.data[0];
  
           setCSGameUser(walletSumm.gameuser);
           setCSGameBetAmt(walletSumm.betAmt);
           setCSGameWinloss(walletSumm.winLoss_AUDIT);
           setCSGameTurnover(walletSumm.sumTurnover);
  
           if (resTrxn.status === "ok") {
             if (resTrxn.data && resTrxn.data.length) {
                  setFilterPptTrxn(resTrxn.data);  
             }
           }
           else{
              setFilterPptTrxn([]);
              //setLoadings(false);
           }     
         }
         else{
          setCSGameUser("N/A");
          setCSGameBetAmt("N/A");
          setCSGameWinloss("N/A");
          setCSGameTurnover("N/A");
          setFilterPptTrxn([]);
         }
        }
      else {
          alertData.title = "Failed";
          alertData.msg = "Transaction not found";
          sharedFailAlert(alertData);  
          setFilterPptTrxn([]);
      }
  
      setTrxLoading(false);
    }

    const setPageURL = async (mobile) => {
      let baseUrl = `${window.location.protocol}//${window.location.hostname}`;
      let path = `${window.location.pathname}`;

      if(window.location.port != "")  { baseUrl += ":" + window.location.port; }

      baseUrl += `${path}?uid=${mobile}`;

      window.location.href = baseUrl;

    }

    const onSearchDownline = async(mobile)=> {
      setSearchMobile(mobile);
      await getUplineData(mobile);
      await getDownlineDataV2(mobile,downlinePageNum, downlinePageSize);
      //await getDownlineData(mobile);
    }

    // const getMemberData = async ()=> {
    //   let baseUrl = `${window.location.protocol}//${window.location.hostname}`;
    //   let path = `${window.location.pathname}`;

    //   if(window.location.port != "") { baseUrl += ":" + window.location.port; }
    //   baseUrl += `${path}?uid=${searchMobile}`;

    //   window.location.href = baseUrl;

    //   onSearchDownline(searchMobile);
    // }

    const getMemberData = async () => {
      let baseUrl = `${window.location.protocol}//${window.location.hostname}`;
      let path = `${window.location.pathname}`;

      if (window.location.port !== "") {
          baseUrl += ":" + window.location.port;
      }

      // Add both uid and shareholder to URL if Admin_CS
      let queryParams = `uid=${searchMobile}`;
      if (role === 'Admin_CS') {
          queryParams += `&shareholder=${shareholder}`;
      }

      baseUrl += `${path}?${queryParams}`;

      // Call onSearchDownline before reload
      await onSearchDownline(searchMobile);
      
      window.location.href = baseUrl;
    }

    const resetUrl = async () => {
      let baseUrl = `${window.location.protocol}//${window.location.hostname}`;
      let path = `${window.location.pathname}`;

      if (window.location.port !== "") {
          baseUrl += ":" + window.location.port;
      }

      baseUrl += `${path}`;
      
      window.location.href = baseUrl;
    }
  
    const getUplineData = async (mobile) => {
      let countSumm = 0 , countUpline = 0 ;
      let requestData = {
          mobile : mobile,
          role : role,
          userid : role === 'Admin_CS' ? shareholderSelection : userId,
      }

      setUlTableLoading(true);

      const resUpline = await csMemberUpline(requestData);
  
      let respUpline = await resUpline.json();

      if (respUpline.status ==="ok") {
        if (Object.keys(respUpline.data).length > 0 ) {
          setTableLoading(false);

          //Get Upline Data
          const newData = Object.keys(respUpline.data.detail).length
            ? respUpline.data.detail.map((x) => {
                setTableLoading(false);
                return {
                  key: countUpline++,
                  downline: false, 
                  userId: x.userid,
                  nickname: x.nickname,
                  generation: x.generation,
                  registered: x.registerdate,
                  lastLogin: x.lastlogin,
                  lastDeposit: x.lastDepositTime,
                  mobile: x.mobile,
                  gameuser: x.gameuser,
                  agent: x.agentUsername,
                  agentId: x.agent, 
                  walletBalance   : x.walBal,
                  commBalance     : x.commBal,
                  turnover        : 
                      x.turnover=== -1
                        ? "N/A"
                        : x.turnover,
                  reqTurnover     : x.turnoverRequired,
                  status:
                    x.loginStatus === 0
                      ? "Lock"
                      : x.loginStatus === 1
                      ? "Active"
                      : x.loginStatus === 2
                      ? "Withdraw Locked"
                      : x.loginStatus === 3
                      ? "Account Locked"
                      : x.loginStatus === 13
                      ? "ResetPin"
                      : "Inactive",
                  action:
                    x.loginStatus === 0
                      ? "Unlock"
                      : x.loginStatus === 1
                      ? "Inactive"
                      : x.loginStatus === 2
                      ? "Withdraw Locked"
                      : x.loginStatus === 3
                      ? "Account Locked"
                      : x.loginStatus === 13
                      ? "ResetPin"
                      : "Lock",
                  totalDepAmt:x.totalDepAmt,
                  totalWitAmt:x.totalWitAmt,
                  totalCommAmt:x.totalCommAmt,
                  dl1Cnt : x.dl1Cnt,
                  uplineMobile: x.uplineMobile,
                  };
              })
            : [];
  
           //console.log(`respUpline.data.detail >> ${JSON.stringify(respUpline.data.detail)}`);
            await setUplineData(newData);

            setUlTableLoading(false);
        }

      }else{
          setUlTableLoading(false);
      }
    }

    const getDownlineDataV2 = async(mobile,pagenum, pagesize)=>{
      let countDL = 0;

      let requestData = {
        // userid    : companyId,
        userid    : role === 'Admin_CS' ? shareholderSelection : userId,
        role      : role,
        mobile    : mobile,
        page      : pagenum,
        pagesize  : pagesize
      }

      console.log("userid >>> ", requestData.userid);
      console.log("role >>> ", requestData.role);
      console.log("mobile >>> ", requestData.mobile);

      SetDLTableLoading(true);

      const responseDL = await csMemberDownlineList(requestData);
        let resultDL = await responseDL.json();
        if (resultDL.status === "ok") {
          const dlData = Object.keys(resultDL.data.detail).length
          ? resultDL.data.detail.map((x) => {
              setTableLoading(false);
              return {
                key: countDL++,
                downline: true, 
                userId: x.userid,
                nickname: x.nickname,
                registered: x.registerdate,
                lastLogin: x.lastlogin,
                lastDeposit: x.lastDepositTime,
                mobile: x.mobile,
                gameuser: x.gameuser,
                agent: x.agentUsername,
                agentId: x.agent, 
                walletBalance   : x.walBal,
                commBalance     : x.commBal,
                turnover        : 
                    x.turnover=== -1
                      ? "N/A"
                      : x.turnover,
                reqTurnover     : x.turnoverRequired,
                status:
                  x.loginStatus === 0 
                    ? "Lock"
                    : x.loginStatus === 1
                    ? "Active"
                    : x.loginStatus === 2
                    ? "Withdraw Locked"
                    : x.loginStatus === 3
                    ? "Account Locked"
                    : x.loginStatus === 13
                    ? "ResetPin"
                    : "Inactive",
                action:
                  x.loginStatus === 0
                    ? "Unlock"
                    : x.loginStatus === 1
                    ? "Inactive"
                    : x.loginStatus === 2
                    ? "Withdraw Locked"
                    : x.loginStatus === 3
                    ? "Account Locked"
                    : x.loginStatus === 13
                    ? "ResetPin"
                    : "Lock",
              totalDepAmt:x.totalDepAmt,
              totalWitAmt:x.totalWitAmt,
              totalCommAmt:x.totalCommAmt,
              dl1Cnt : x.dl1Cnt,
              };
            })
          : [];

          setDownlineData(dlData);

          let spec = resultDL.data.detail.filter(obj=> obj.mobile == "66962567270");

          // console.log(`newData >> ${JSON.stringify(spec)}`);

          const dlDataSummary = Object.keys(resultDL.data.summary).length
                        ? resultDL.data.summary.map((x) => {
                          return {
                              current : x.current,
                              pagesize: x.pagesize,
                              mobile  : x.mobile,
                              totalDownline : x.totalDownline
                          };
                        })
                        : [];

                        
            setDownlineTotal(dlDataSummary?.[0]?.totalDownline ?? 10 );

            SetDLTableLoading(false); 
        }

        SetDLTableLoading(false);
    }


    // const handleMemberPhone = (e) => {
    //   e.preventDefault();
    //   alertData.title = "Error";
    //   alertData.msg = "Copy Not Allowed";
    //   sharedErrorAlert(alertData);
    // }
    ////#endregion
    

    ////#region  Columns
    let bankColumns = [
      {
        title: 'Bank Name',
        dataIndex: 'bank'
      },
      {
        title: 'Account Name',
        dataIndex: 'acctname',
        
      },
      {
        title: 'Account Number',
        dataIndex: 'acctnum',
        width: "27.55%",
        render : (text)=> {
          return editMemberEnable ? 
              (
                <span>{text}</span>         
              ):
              (
                <span>{FormatMask(text)}</span>
              )
        }
        
      },
      {
        title : "Status",
        dataIndex :"actionStatus",
        width : "15%",
        sorter: (a, b) => a.status - b.status,
        render: (text, record) => {
          switch (record.status) {
              case 1:
                    return <Badge status="success" text="Active" />;       
              case 5:
              case 7:
                  return <Badge status="warning" text="Pending" />;
              case 9:
                return <Badge status="error" text="Not Approved" />;
  
          }
        },
      },
      {
        title:"Action",
        dataIndex:'userid',
        render: (text , record) => {
          return  (
            <div             
            style={{
              flexDirection: "row",
              display: "flex",
              float:"left"
            }}>
              {/* { 
                editMemberEnable?
                (
                  <Popconfirm title="Sure to delete?" onConfirm={() => {                   
                      onDeleteBankAcc(record.key)                    
                  }}>
                    <Button
                      type="danger"
                      shape="circle"
                      icon={<DeleteOutlined />}
                    />
                  </Popconfirm>
                ):
                <></>
              } */}
            </div>
          );
        },
      }
    ];
  
  
    bankColumns = editMemberEnable? bankColumns.slice(0,5): bankColumns.slice(0,4);

    const memberColumns = [
      {
        align: "center",
        title: "Mobile",
        dataIndex: "mobile",
        width: "12%",
        fixed:"left",
        render: function(text, record,row) {
          return (
            <p style={styles.noHighlight}>
              {
                record.downline ?
                (
                  <>
                    <Button
                      onClick={() => {
                        onSearchDownline(record.mobile);
                        setPageURL(record.mobile);
                      }}
                    >
                      {record.mobile}
                      {/* { issuper? (record.mobile):FormatMask(record.mobile).slice(-8) } */}
                    </Button>
                   </>
                ):
                <span>
                  {record.mobile}
                  {record.uplineMobile ?
                      (<BtnViewUpline uplineMobile={record.uplineMobile}/>):(<></>)
                  }
                  {/* { issuper? (record.mobile):FormatMask(record.mobile).slice(-8) } */}
                </span>
              }  
            </p>
          )
        }
      },
      {
        align: "center",
        title: <div>Game <br/> User</div>,
        dataIndex: "gameuser",
        render: function(text,record) {
            return {
              props: {
                style:  styles.nowrap
              },
              children: (text===undefined) ? <>{"N/A"}</>:<>{text}</>
            };
        }
      },
      // {
      //   align: "center",
      //   title: "Agent",
      //   dataIndex: "agent",
      //   width:"5%",
      //   render: function(text,record) {
      //     return {
      //       props: {
      //         style:  styles.nowrap
      //       },
      //       children: <>{text}</>
      //     };
      //   }
      {
        align: "center",
        title: "Wallet",
        dataIndex: "walletBalance",
        render:function(text,record) {
          return FormatPt(text);
        }
      },
      {
        align: "center",
        title: "Comm.",
        dataIndex: "commBalance",
        render:function(text,record) {
          if (record.commBalance < 0) {
            return <div>{FormatPt("0")}</div>;
          }else {
            return <div>{FormatPt(text)}</div>;
          }
        }
      },
      // {
      //   align: "center",
      //   title: "Turnover",
      //   dataIndex: "turnover",
      //   // width: "8%",
      //   render:function(text) {
      //     if (text!='N/A') {
      //       return FormatPt(text);
      //     }else{
      //       return text;
      //     }
      //   }
  
      // },
      // {
      //   align: "center",
      //   title: <div>Required <br/> Turnover</div>,
      //   dataIndex: "reqTurnover",
      //   render:function(text) {
      //     return FormatPt(text);
      //   }
      // },
      {
        align: "center",
        title: <div>Last <br/>Login</div>,
        dataIndex: "lastLogin",
        width: "10%",
        render : (text)=>{
          return <div>{moment(text).format('YYYY-MM-DD')}<br/>{moment(text).format('HH:mm:ss')}</div>
        }
      },
      {
        align: "center",
        title: <div>Last <br/>Deposit</div>,
        dataIndex: "lastDeposit",
        width: "8%",
        render : (text)=>{
          return !text ? "": <div>{moment(text).format('YYYY-MM-DD')}<br/>{moment(text).format('HH:mm:ss')}</div>;
        }
      },
      {
        align: "center",
        title : <div>1st Downline <br/>Count</div>,
        dataIndex : "dl1Cnt",
        render: (text, record) => {
          let dlStyle = parseInt(text)==0 ? styles.attnFontStyle:{};
          let dlBtn   = (parseInt(text)==0)? <></>: <BtnViewDLSummary memberUserId={record.userId} mobile={record.mobile}/>;
          return (<><span style={dlStyle}>{text}</span>
                  {dlBtn}
                  </>
                  )
        }
      },
      {
        align: "center",
        title: <div>Date <br/>Registered</div>,
        dataIndex: "registered",
        width:"10%",
        render : (text)=>{
          return <div>{moment(text).format('YYYY-MM-DD')}<br/>{moment(text).format('HH:mm:ss')}</div>
        }
      },
      {
        align: "center",
        title :<div>Total <br/> Deposit Amt.</div>,
        dataIndex:"totalDepAmt",
        render: (text)=> {
          return FormatPt(text);
        }
      },
      {
        align: "center",
        title :<div>Total <br/> Withdraw Amt.</div>,
        dataIndex:"totalWitAmt",
        render: (text)=> {
          return FormatPt(text);
        }
      },
      {
        align: "center",
        title :<div>Total <br/> Comm. Amt.</div>,
        dataIndex:"totalCommAmt",
        render: (text)=> {
          return FormatPt(text);
        }
      },
      {
        align: "center",
        title: "Status",
        dataIndex: "status",
        width: "10%",
        render: (text, record) => {
          switch (text) {
            case "Lock":
              return <Badge status="warning" text="Lock" />;
  
            case "Active":
              return <Badge status="success" text="Active" />;
  
            case "Withdraw Locked":
              return <Badge status="warning" text="Withdraw Locked" />;
  
            case "Account Locked":
              return <Badge status="warning" text="Account Locked" />;
  
            case "ResetPin":
                return <Badge status="success" text="Reset Pin"/>;

            default:
              return <Badge status="error" text="Inactive" />;
          }
        },
      },
      {
        align: "center",
        title: "Action",
        dataIndex: "status",
        width: "11%",
        render: (text, record) => {
          let actionStatus = record.action;
          return (<div style={{display:"table"}}>
            <div style={{display:"table-cell"}}>
              {
                record.action =="Inactive"?
                  <BtnLockMember memberUserId={record.userId}/> :
                record.action =="Unlock"? 
                <BtnUnLockMember memberUserId={record.userId}/> :
                <div style={{width:"32px"}}>&nbsp;&nbsp;</div>
              }
            </div>
            <div style={{display:"table-cell"}}><BtnEditMember memberUserId={record.userId} mobile={record.mobile} gameuser={record.gameuser} turnover={record.turnover} reqTurnover={record.reqTurnover}/></div>
            <div style={{display:"table-cell"}}><BtnViewTrxn memberUserId={record.userId} mobile={record.mobile}/></div>
            <div style={{display:"table-cell"}}><BtnViewTurnover memberUserId={record.userId} mobile={record.mobile}/></div>
            {/* <BtnViewTrxnSummary mobile={record.mobile} totalDepAmt={record.totalDepAmt} totalWitAmt={record.totalWitAmt} totalCommAmt={record.totalCommAmt}/> */}
           
            
         </div>)
        }
      }
 
    ]


    let gameColumns = [
      {
        align: "center",
        title: "Ticket Time",
        dataIndex:"ticketTime",
        width: "200px",
        ellipsis: true,
        fixed:"left",
      },
      {
        align: "center",
        title: "Game Code",
        dataIndex: "gamecode",
        width: "200px",
        ellipsis: true,
      },
      {
        ellipsis: true,
        align: "center",
        title: "Bet Amount",
        dataIndex: "betAmount",
        width: "150px",
        render:function(record) {
          return FormatPt(record);
        }
      },    
      {
        ellipsis: true,
        align: "center",
        title: "Win / Lose Amount",
        dataIndex: "winloseAmount",
        width: "150px",
        render:function(record) {
          return FormatPt(record);
        }
      },
      {
        ellipsis: true,
        align: "center",
        title: "Win Loss",
        dataIndex: "winloss",
        width: "150px",
        render:function(record) {
          return FormatPt(record);
        }
      },
      {
        ellipsis: true,
        align: "center",
        title: "Turnover",
        dataIndex: "turnover",
        width: "150px",
        render:function(record) {
          return FormatPt(record);
        }
      },
      {
        ellipsis: true,
        align: "center",
        title: "Status",
        dataIndex: "status",
        width: "200px",
        render: (text, record) => {
          switch (text) {
              case "L":
                  return "Lose"
              case "D":
                return "Draw"
              case "W":
                return "Win"
              default:
                return "";

          }
        }
      },
    ];
    
    let pptColumns = [
      {
        align: "center",
        title: "Date Time",
        dataIndex:"date",
        width: "200px",
        ellipsis: true,
        //sorter: (a, b) => new Date(a.date) - new Date(b.date)
      },
      // {
      //   align: "center",
      //   title: "Trxn ID",
      //   dataIndex:"trxnID",
      //   width: "200px",
      //   // ellipsis: true,
      // },
      {
        align: "center",
        title: "Transaction Type",
        dataIndex: "trxnType",
        width: "200px",
        ellipsis: true,
        filters: [
          {
            text: 'Commision',
            value: 'Comm',
          },
  
          {
            text: 'Game-in',
            value: 'Game-In',
          },
          {
            text: 'Game-out',
            value: 'Game-Out',
          },
          {
            text: 'Promotion',
            value: 'Promotion',
          },
          {
            text: 'Recovery',
            value: 'Recovery',
          },
          {
            text: 'Topup',
            value: 'Topup',
          },
          {
            text: 'Withdraw',
            value: 'Withdraw',
          },
        ],
        filterMultiple: false,
        onFilter: (value, record) => record.trxnType.toLowerCase().indexOf(value.toLowerCase()) === 0 ,
      },
      {
        align: "center",
        title: "Game Provider",
        dataIndex: "gamecode",
        ...getColumnSearchProps("gamecode"),
      },
      {
        ellipsis: true,
        align: "center",
        title: "Amount",
        dataIndex: "amount",
        width: "150px",
        render : (text) => {
          return <>{FormatPt(text)}</>
        },
        //...getColumnSearchProps("amount"),
      }, 
      {
        ellipsis: true,
        align: "center",
        title: "Actual Amount",
        dataIndex: "actualAmount",
        width: "150px",
        render :(text)=> {
          if (text===0) {
              return <>-</>;
          }else {
              return <>{FormatPt(text)}</>
          }
        },
        ...getColumnSearchProps("actualAmount"),
      },   
      {
        ellipsis: true,
        align: "center",
        title: "Commission",
        dataIndex: "comm",
        width: "150px",
      },
      {
        ellipsis: true,
        align: "left",
        title: "Status",
        dataIndex: "status",
        width: "200px",
        render: (text, record) => {
          switch (text) {
              case 1:
                  if (record.trxnType =="Withdraw") {
                      return <Badge text="Bank Process Complete" color={"blue"} />;
                  }
                  return <Badge text="Approved" color={"blue"} />;       
              case 3:
                  return <Badge text="Approved" color={"blue"} />;
              case 9:
                return <Badge status="error" text="Rejected" />;
              default:
                 return <span>{text}</span>;
  
          }
        }
      },
    ];
    ////#endregion

    const styles = {
      pageHeaderContainer: {
        justifyContent: isMobile ? "" : "space-between",
        display: "flex",
        flexDirection: isMobile ? "column" : "",
        alignItems: isMobile ? "center" : "",
      },
      refreshBtn: {
        marginLeft: 10,
      },
      memberBtn: {
        width:"150px", 
        marginBottom:"8px"
      },
      lockMemberBtn:{
        //width: "70px",
        //marginRight: 20,
        backgroundColor: "#F7B105",
        border: "none",
        color: "#FFFFFF",
      },
      unlockMemberBtn:{
        backgroundColor: "#0000FF",
        border: "none",
        color: "#FFFFFF",
      },
      tabelContainer: {
        backgroundColor: "#FFFFFF",
        padding: 24,
      },
      tableDivide50: {
        height:"60%",
      },
      tableDivide40: {
        height:"30%",
      },
      paginationStyle: {
        marginTop: 50,
      },
      formItem: {
        width:"80%"
      },
      datePickerStyle: {
        width: 260,
      },
      cardContainer: {
        display: "flex",
        width: "100vw",
        overflowX: "scroll",
        overflowY: "hidden",
        flexDirection: "row",
        paddingTop: 10,
        paddingBottom: 10,
        height:"5%"
      },
      cardStyles: {
        borderRadius: 20,
        fontSize: 15,
        //fontSize: 30,
        boxShadow: "5px 8px 5px 5px rgba(208, 216, 243, 0.6)",
        marginLeft: 20,
        height: "30%",
        //height: 150,
        marginRight: 20,
      },
      cardHeadStyles: {
        backgroundColor: "#1DA57A",
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        color: "#FFFFFF",
        fontSize: 25,
        width: 350,
      },
      cardTxt: {
        paddingLeft: 20,
        height: 60,
        alignItems: "center",
        display: "flex",
      },
      hide : {
        display:"none",
      },
      bankAmountStyle:{
        marginLeft:"auto",
        marginRight:0,
      },
      tagStyle:{
        width:"92px",
      },
      buttonStyle:{
        fontSize:"14px"
      },
      memberSearchStyle:{
        width:200,
      },
      memberTrxnStyle:{
        marginLeft:"280px",
      },
      actionBtnListStyle:{
        listStyleType: "none",
        
      },
      actionBtnListItemStyle:{
        display:"inline",
        width: "3%",
      },
      trxnSearchDatePicker:{
        marginBottom:"1%",
      },
      gen1Style:{
        display: parseInt(gen1Count) > 0 ? "block" :"none",
      },
      numberStyle:{
        textAlign:"right",
      },
      attn: {
        fontSize:"20px",
      },
      formItem: {
        width:"80%"
      },
      formItem2: {
        width:"83.54%"
      },
      width80:{
        width:"80%"
      },
      fullWidth:{
        width:"100%"
      },
      phoneNumStyle:{
        width:"76.58%"
      },
      downlineFontStyle: {
        fontSize:"13px",
        fontWeight:"bolder",
      },
      attnFontStyle:{
        color:"red",
        fontWeight:"bolder",
      },
      addBankTabStyle:{
        backgroundColor:"#f5f3f3"
      },
      addBankFormStyle:{
        height:"300px",
        marginLeft: "2.0%",
        marginTop: "1.1%",
      },
      editBankFormStyle:{
        marginLeft: "2.0%",
        marginTop: "1.1%",
      },
      nowrap:{
        whiteSpace:"no-wrap",
      },
      underline:{
        textDecorationLine: "underline",
      },
      noHighlight : {
        MozUserSelect: "none",
        WebkitUserSelect: "none",
        msUserSelect: "none"
      },
      rotate180Deg  : {
        transform:"rotate(180deg)"
      },
      authorized : {
        display : issuper ? "block":"none",
      },
      unauthorized: {
        display : issuper? "none":"block",
      }
    };
    return (
      <div className="reportDownline">      
        {
          repDownlineVw(role)?
          <div>
          <PageHeader ghost={false}>
            <div style={styles.pageHeaderContainer}>
              <div style={{float:"left"}}>
                  {isAdminCS && (
                    <Select
                      value={shareholderSelection}
                      optionFilterProp="children"
                      ref={reportShareholderRef}
                      filterOption={(input, option) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                      }
                      placeholder="Please select a shareholder"
                      style={{...styles.refreshBtn,...styles.memberSearchStyle, width: 230}}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      allowClear
                      onChange={(value, option) => {
                        setShareholderSelection(value);
                        setShareholder(option.username);
                      }}
                    >
                      {shareholderOptions.map((option) => {
                        return (
                          <Option key={option.key} value={option.shareholder} username={option.username}>
                            {option.username}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                  <Input
                  type="text"
                  id="searchMobileNo"
                  placeholder="Enter Mobile Number"
                  style={{...styles.refreshBtn,...styles.memberSearchStyle}}
                  onChange={(e) => {
                      setSearchMobile(e.target.value.replace(/\D|^0/g, ""));                
                  }}
                  allowClear={true}
                  value={searchMobile}
                  ref={reportMobile}
                  >
                  </Input>
                  <Button 
                  style={{...styles.refreshBtn,...styles.buttonStyle}}
                  type="primary"
                  onClick={()=>{
                      getMemberData();
                  }}
                  >
                      Search Mobile
                  </Button>
                <Button
                    style={{...styles.refreshBtn,...styles.buttonStyle}}
                  type="primary"
                  onClick={() => {
                    setSearchMobile("");
                    setShareholderSelection(null);
                    resetUrl();
                    // getMemberData();
                  }}
                >
                  Reset
                </Button>
              </div>
            </div>
            <div style={{marginTop:"20px"}}>
                <div style={{...styles.cardContainer,...styles.hide}}>
                  <Card
                    title={cardTitle}
                    style={{...styles.cardStyles}}
                    headStyle={{...styles.cardHeadStyles,fontSize:"12px"}}
                  >
                    <Form
                          labelCol={{ span: 16 }}
                          wrapperCol={{ span: 16 }}
                          style={{textAlign:"left"}}
                    >
                      <Form.Item
                        label="1st Downline Count"
                      >
                          <span>{gen1Count}</span>
                      </Form.Item>
                      <Form.Item
                        label="2nd Downline Count"
                      >
                          <span>{gen2Count}</span>
                      </Form.Item>
                      <Form.Item
                        label="Total Downline Count"
                      >
                          <span>{genAllCount}</span>
                      </Form.Item>
                    </Form>
                  </Card>
                </div>
            
            </div>
          </PageHeader>
          </div>
        :<></>
        }
        <Modal
        title={selected ? "Lock Reason" : "Inactive Reason"}
        visible={visible}
        onOk={() => {
          handleReasonOk(0);
        }}
        onCancel={handleReasonCancel}
        okText={selected ? "Lock" : "Inactive"}
        maskClosable={false}
        cancelButtonProps={{ type: "primary" }}
        okButtonProps={{ type: "danger", loading: loadings[0] }}
      >
        <TextArea
          rows={4}
          onChange={(e) => setReason(e.target.value)}
          value={reason}
        />
        </Modal>
        <Modal 
          title ={viewMemberDlgTitle}
          visible={editMemberModal}
          onOk={updateMemberInfo}
          onCancel= {() => {
            setEditMemberModal(false);
            clearAllFields();
          }}
          cancelButtonProps={{ type: "primary" }}   
          okButtonProps={
              editMemberEnable? 
              (
                { type: "danger", loading: loadings[0]  , disabled: editMemberDisable }
              ):
              (
                {style:styles.hide}
              )
          } 
          okText={viewMemberDlgOK}
          cancelText="Close"
          center   
          width={850}
        >
          {onEditMemberForm()}
        </Modal>
        <Modal
          title={"Transaction Summary"}
          visible={trxnSummVisible}
          onCancel={() => {
            onCancelTrxn();
            setTrxnSummVisible(false);
          }}
          maskClosable={false}
          cancelButtonProps={{ type: "primary" }}
          okButtonProps={{ style:{display: "none"} }}    
          cancelText="Close"  
          >
          <Form
                labelCol={{ span: 16 }}
                wrapperCol={{ span: 16 }}
                style={{textAlign:"left"}}
          >
            <Form.Item
              label="Total Deposit Amount"
            >
                <span style={styles.downlineFontStyle}>{totalDepAmount}</span>
            </Form.Item>
            <Form.Item
              label="Total Withdraw Amount"
            >
                <span style={styles.downlineFontStyle}>{totalWitAmount}</span>
            </Form.Item>
            <Form.Item
              label="Total Comm. Amount"
            >
                <span style={styles.downlineFontStyle}>{totalCommAmount}</span>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title={"Downline Line Summary"}
          visible={downlineVisible}
          onCancel={() => {
            onCancelDownline();
            setDownlineVisible(false);
          }}
          maskClosable={false}
          cancelButtonProps={{ type: "primary" }}
          okButtonProps={{ style:{display: "none"} }}    
          cancelText="Close"  
          >
          <Form
                labelCol={{ span: 16 }}
                wrapperCol={{ span: 16 }}
                style={{textAlign:"left"}}
          >
            <Form.Item
              label="1st Downline Count"
            >
                <span style={styles.downlineFontStyle}>{gen1Count1}</span>
            </Form.Item>
            <Form.Item
              label="2nd Downline Count"
            >
                <span style={styles.downlineFontStyle}>{gen2Count1}</span>
            </Form.Item>
            {/* <Form.Item
              label="Total Downline Count"
            >
                <span style={styles.downlineFontStyle}>{genAllCount1}</span>
            </Form.Item> */}
          </Form>
        </Modal>
        <Modal 
          title={viewTrx=="trxn" ? "Transactions Details" : "Turnover Details"}
          visible={puppetVisible}
          style={{ top: "2%", marginLeft: "20.55%" }}
          onCancel={()=>{
              setPuppetVisible(false);
              //onCancelApproveTrxn();
          }}
          width={1300}
          footer={[
            <Button key="back"
            align="left"
            onClick = {()=> {
              setPuppetVisible(false);
            }}
            >
              Close
            </Button>
          ]}
        >
         
        <Row style={styles.refreshBtn}>
            {
              viewTrx =="trxn" ? 
                (<><b>User Mobile :</b><p  style={styles.noHighlight}>{issuper ? (csMemberMobile): FormatMask(csMemberMobile).slice(-8)}</p>&nbsp;<b>Member Wallet As of :</b> {csMemberWalletDt} &nbsp;&nbsp;<b>Balance :</b> {csMemberWalletBal}&nbsp;&nbsp;<b>Comm:</b>{csMemberWalletComm}</>):
                (<><b>User Mobile :</b><p  style={styles.noHighlight}>{issuper ? (csMemberMobile): FormatMask(csMemberMobile).slice(-8)}</p>&nbsp;<b>Game User :</b> {csGameUser} &nbsp;&nbsp;<b>Bet Amount :</b> {csGameBetAmt}&nbsp;&nbsp;<b>Win/Loss :</b>{csGameWinloss}&nbsp;&nbsp;<b>Turnover :</b>{csGameTurnover}  </>)
            }            
        </Row>
          <div style={styles.tabelContainer}>
          <div style={styles.trxnSearchDatePicker}>
            <DatePicker
                key="2"
                id="datePicker1"
                style={styles.datePickerStyle}
                onChange={trxDatePicker}
                value={trxDateFrom}
                ref={reportDateFrom}
                placeholder={"Select Date From"}
                disabledDate={(current)=> {
                  return current > moment().subtract(1, 'day');
                }}/>
              <DatePicker
                key="3"
                id="datePicker2"
                style={styles.datePickerStyle}
                onChange={trxDatePicker2}
                value={trxDateTo}
                ref={reportDateTo}
                placeholder={"Select Date To"}
                disabledDate={(current)=> {
                  let trxDateFrom = date;
                  if (trxDateFrom) {
                    return current < trxDateFrom || current > moment().subtract(1, 'day');
                  }else {
                    return  current > moment().subtract(1, 'day');
                  }
                }}
                />
                <Button
                  type={"primary"}
                  style={styles.searchResetStyle}
                  icon={<SearchOutlined />}
                  onClick={searchTrxn}
                />
            </div>
            <Table
              loading={trxLoading}
              //columns ={pptColumns}
              columns={viewTrx=="trxn" ? pptColumns : gameColumns}
              dataSource= {filteredPptTrxn }
              scroll={{ x: isMobile ? "110%" : "100%", y: null }}
              pagination={{
                defaultPageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: settings.pageSizeOptions,
                style: styles.paginationStyle,
                alignmentBottom: 'right',
              }}
            />
          </div>
        </Modal>
        {repDownlineVw(role)?
        <div style={{...styles.tabelContainer}} className="body">
            {/* <Button
                onClick={()=> {
                  history.goBack();
                }}
                style={{marginBottom:"0.30%"}}
            >
                 Back
            </Button> */}
            <Row style={{marginTop:"0.5%"}}>
              <b>Upline</b>
            </Row>
            <Table
                columns={memberColumns}
                dataSource={uplineData}
                pagination={false}
                loading={ulTableLoading}
            />
            <Row style={{marginTop:"0.5%"}}>
               <b>Downline</b>
            </Row>
            <Table
                columns={memberColumns}
                dataSource={downlineData}
                pagination={{
                  defaultPageSize: 10,
                  showSizeChanger: true,
                  pageSizeOptions: settings.pageSizeOptions,
                  style: styles.paginationStyle,
                  alignmentBottom: 'right',
                  total: downlineTotal,
                  //showTotal: total => `Total ${downlineTotal}  Downline(s)`,
                }}
                loading={dlTableLoading}
                onChange={handleDLTableChange}
            />
        </div>:<></>
        }
        {
          repDownlineVw(role)?
          <>
          </>:
          <>
            <div>
              <div style={{textAlign:"center",color:"red",fontSize:"150%"}}><h1 style={{color:"red"}}>Not Allowed to Access This Page</h1></div>
            </div>
          </>
        }

        </div>
        
    );
  }


