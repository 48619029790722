//#region ---------- Modules ----------

import React, { useEffect, useState } from "react";
import {
  PageHeader,
  Table,
  Button,
  Modal,
  Form,
  Input,
  Badge,
  Row,
  Tooltip,
  Switch,
  Select,
  Popconfirm,
} from "antd";

//#endregion

//#region ---------- Imports ----------
import {
  getDomainList,
  createDomain,
  updateDomainStatus,
  updatePrimaryDomain,
  getDomainStatusDB,
  getDomainZoneID,
  deleteDNSRecord,
  updateDNSRecord,
  addDNSRecord,
  getDNSRecords,
  // getCompanyProfileData,
} from "../../config/networkConfig";
import {
  alertData,
  sharedSuccessAlert,
  sharedErrorAlert,
  sharedFailAlert,
} from "../../common/constants/sharedMsgBox";
import {
  CopyOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { onboardDomainVw } from "../../library/permission";
import { CNotAuthorized } from "../../common/components/CNotAuthorized";

//#endregion

const DnsRecordActionType = {
  Create: 1,
  Edit: 2,
};

export default function OnboardDomain() {
  //#region ---------- Variables ----------
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [tableLoadings, setTableLoadings] = useState(false);
  const [isValidDomain, setIsValidDomain] = useState(true);
  const [newDomain, setNewDomain] = useState("");
  const [formLoading, setFormLoading] = useState(false);
  const [domainList, setDomainList] = useState([]);
  const [primaryDomLdg, setPrimaryDomLdg] = useState(false);

  const now = new Date().toLocaleTimeString();
  // const [time, setTime] = useState(now);
  const { issuper, userId, username, role, companyId, compCode } = useSelector(
    (state) => state.login
  );
  const [isAddOrModifyDnsRecord, setIsAddOrModifyDnsRecord] = useState();
  const [dnsRecordAction, setDnsRecordAction] = useState(
    DnsRecordActionType.Create
  );
  const [dnsRecordFormData, setDnsRecordFormData] = useState({content: "", type: "TXT", id: "", zoneId: ""});
  
  useEffect(() => {
    retrieveDomainList();
  }, []);

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 100 },
  };

  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  //#endregion

  //#region ---------- Functions ----------
  const onReset = () => {
    setNewDomain("");
    setIsValidDomain(true);
    if (!formLoading) {
      setVisible(false);
    }
  };

  const columns = [
    {
      title: "Domain",
      dataIndex: "domain",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => {
        switch (record.status) {
          case 0:
            return <Badge status="warning" text="Pending" />;
          case 1:
            return <Badge status="success" text="Active" />;
        }
      },
    },
    {
      title: "Nameserver 1",
      dataIndex: "nameservers",
      render: (text, record) => {
        return (
          <Row>
            <div style={{ marginRight: 10 }}>{text[0]}</div>
            <Tooltip title="Copy this Nameserver">
              <Button
                type="primary"
                shape="circle"
                onClick={() => navigator.clipboard.writeText(text[0])}
                icon={<CopyOutlined />}
                size="small"
              ></Button>
            </Tooltip>
          </Row>
        );
      },
    },
    {
      title: "Nameserver 2",
      dataIndex: "nameservers",
      render: (text, record) => {
        return (
          <Row>
            <div style={{ marginRight: 10 }}>{text[1]}</div>
            <Tooltip title="Copy this Nameserver">
              <Button
                type="primary"
                shape="circle"
                onClick={() => navigator.clipboard.writeText(text[1])}
                icon={<CopyOutlined />}
                size="small"
              ></Button>
            </Tooltip>
          </Row>
        );
      },
    },
    {
      title: "Primary Domain",
      dataIndex: "isPrimary",
      render: (text, record) => {
        return (
          <Row>
            <Switch
              checkedChildren="YES"
              unCheckedChildren="NO"
              onChange={() => setPrimaryDom(record.domain)}
              checked={text == 1 ? true : false}
              id={record.domain}
              loading={primaryDomLdg}
            ></Switch>
          </Row>
        );
      },
    },
    {
      title: "Domain Property",
      dataIndex: "domainProperty",
      render: (data, record) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", gap: 5, minWidth: 200 }}>
            {data.map((x, i) => {
              return (
                  <div key={i} style={{ display: "flex", gap: 5, justifyContent: "space-between" }}>
                    <div style={{wordBreak: "break-all"}}>[{x.type}] - {x.content}</div>
                    <div style={{display: "flex", gap: 5}}>
                    <Popconfirm
                      title={`Delete [${x.type}] - ${x.content} record?`}
                      onConfirm={() => {
                        handleDeleteDnsRecord(x.zone_id, x.id)
                      }}
                      // onCancel={() => {}}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button
                        type="danger"
                        shape="circle"
                        size="small"
                        icon={<DeleteOutlined />}
                      />
                    </Popconfirm>
                    <Button
                      type="primary"
                      shape="circle"
                      size="small"
                      icon={<EditOutlined />}
                      onClick={() => {
                        handleDnsActionClick(DnsRecordActionType.Edit);
                        setDnsRecordFormData((prev) => ({
                          ...prev,
                          name: x.name,
                          content: x.content,
                          type: x.type,
                          id: x.id,
                          zoneId: x.zone_id
                        }));
                      }}
                    />
                    </div>
                  </div>
              );
            })}
            <Button
              type="primary"
              onClick={() => {
                setDnsRecordFormData((prev) => ({
                  ...prev,
                  zoneId: record.zoneId
                }));
                handleDnsActionClick(DnsRecordActionType.Create);
              }}
            >
              Add Record
            </Button>
          </div>
        );
      },
    },
    // },
  ];

  const handleDnsActionClick = (action) => {
    setDnsRecordAction(action);
    setIsAddOrModifyDnsRecord(true);
  };

  const handleInputDomain = (e) => {
    const inputDomain = e.target.value.replace(
      /(http:\/\/|https:\/\/|http:\/\/www\.|https:\/\/www\.|http|www\.|https|\/|\s)+/g,
      ""
    );
    setNewDomain(inputDomain);
    const domainRegex = /^[^\s]+\.[^\s]+$/;
    setIsValidDomain(domainRegex.test(inputDomain));
  };

  const splitNameservers = (nameserver) => {
    let nameserverArr = nameserver.split(",");
    return nameserverArr;
  };

  const setPrimaryDom = async (domain) => {
    setPrimaryDomLdg(true);
    let requestData = {
      domain: domain,
    };
    console.log("domainList >>", domainList);
    const result1 = await getDomainStatusDB(requestData);
    let response1 = await result1.json();

    if (response1.status === "ok" && response1.data[0].status === 1) {
      console.log("response1 >>", response1);
      const result2 = await updatePrimaryDomain(requestData);
      let response2 = await result2.json();

      if (response2.status === "ok") {
        setPrimaryDomLdg(false);
        alertData.title = "Success";
        alertData.msg = response2.msg;
        sharedSuccessAlert(alertData);
        retrieveDomainList();
      } else {
        setPrimaryDomLdg(false);
        alertData.title = "Failed";
        alertData.msg = response2.msg;
        sharedFailAlert(alertData);
      }
    } else {
      setPrimaryDomLdg(false);
      alertData.title = "Failed";
      alertData.msg =
        "Domain is not active, please wait for the domain to be active and try again.";
      sharedFailAlert(alertData);
      retrieveDomainList();
    }
  };

  const updateDomain = async (domain) => {
    setTableLoadings(true);
    let requestData = {
      domain: domain,
    };

    const result = await updateDomainStatus(requestData);
    let response = await result.json();

    if (response.status === "ok") {
      if (response.data === "active") {
        domainList[domainList.length - 1].status = 1;
      }
      setTableLoadings(false);
    } else {
      alertData.title = "Failed";
      alertData.msg = response.msg;
      sharedFailAlert(alertData);
    }
  };

  const retrieveDomainList = async () => {
    setTableLoadings(true);
    let requestData = {
      columnRequire: [
        "DomainName AS domain",
        "Status AS status",
        "NameServer AS nameserver",
        "isPrimary AS isPrimary",
      ],
    };

    let response = await getDomainList(requestData);
    let result = await response.json();

    if (result.status === "ok") {
      if (Object.keys(result.data).length > 0) {
        let count = 0;
        // let domainListData = result.data.map((obj) => {
        //   return {
        //     key: count++,
        //     domain: obj.domain,
        //     nameservers: splitNameservers(obj.nameserver),
        //     status: obj.status,
        //     isPrimary: obj.isPrimary,
        //   };
        // });
        let domainListData = [];
        for (let i = 0; i < result.data.length; i++) {
          let dnsRecordResult = await handleGetDNSRecord(result.data[i].domain);
          domainListData.push({
                  key: count++,
                  domain: result.data[i].domain,
                  nameservers: splitNameservers(result.data[i].nameserver),
                  status: result.data[i].status,
                  isPrimary: result.data[i].isPrimary,
                  domainProperty: dnsRecordResult.dnsRecord,
                  zoneId: dnsRecordResult.zoneId
          });
        }

        if (domainListData.length > 0) {
          console.log("domainListData >>", domainListData);
          for (let i = 0; i < domainListData.length; i++) {
            domainList.push(domainListData[i]);
            await updateDomain(domainListData[i].domain);
          }
          setDomainList(domainListData);
        }
      }
    } else {
      alertData.title = "Failed";
      alertData.msg = result.msg;
      sharedFailAlert(alertData);
    }
    setTableLoadings(false);
  };

  const addDomain = async () => {
    setFormLoading(true);
    let requestData = {
      domain: newDomain,
    };

    if (newDomain == "") {
      alertData.title = "Field Required";
      alertData.msg = "Please fill in Domain!";
      sharedErrorAlert(alertData);
      setFormLoading(false);
      return;
    }

    const check1 =
      /^(http:\/\/|https:\/\/|http:\/\/www\.|https:\/\/www\.|http|www\.|https)$/;

    if (check1.test(newDomain)) {
      console.log("check1.test(newDomain) >>>", check1.test(newDomain));
      alertData.title = "Field Error";
      alertData.msg = `Please enter domain with valid format!`;
      sharedErrorAlert(alertData);
      setFormLoading(false);
      return;
    }

    if (!isValidDomain) {
      console.log("isValidDomain >>>", isValidDomain);
      alertData.title = "Field Error";
      alertData.msg = `Please enter domain with valid format!`;
      sharedErrorAlert(alertData);
      setFormLoading(false);
      return;
    }

    const result = await createDomain(requestData);
    console.log("createDomain result>>>", result);
    let response = await result.json();
    if (response.status === "ok") {
      console.log("createDomain response>>>", response);
      alertData.title = "Success";
      alertData.msg = response.msg;
      sharedSuccessAlert(alertData);
      onReset();
    } else {
      alertData.title = "Failed";
      alertData.msg = response.msg;
      sharedFailAlert(alertData);
    }
    setFormLoading(false);
    retrieveDomainList();
  };

  const handleRefreshDNSRecord = async() => {
    let latestDomainList = [];
    for (let i = 0; i < domainList.length; i++) {
      let dnsRecordResult = await handleGetDNSRecord(domainList[i].domain);
      latestDomainList.push({
              ...domainList[i],
              domainProperty: dnsRecordResult.dnsRecord
      });
    }
    setDomainList(latestDomainList);
  }

  const handleGetDNSRecord = async (domain) => {
    let result = {dnsRecord:[], zoneId: ""};
    try {
      let oriResponse = await getDomainZoneID(domain);
      let response = await oriResponse.json();
      if (response.status === "ok") {
        if (response.data?.result[0]?.name === domain) {
          result.zoneId = response.data?.result[0].id;
          let requestData = {
            zoneId: response.data?.result[0].id
          }
          let oriDnsResponse = await getDNSRecords(requestData);
          let dnsResponse = await oriDnsResponse.json();
          if (dnsResponse.status === "ok" && dnsResponse.data.result?.length){
            result.dnsRecord = dnsResponse.data.result;
          }
        }
      }
    } catch(e) {
      console.log("get dns record fail", e)
    }
    return result;
  };

  // Handle change for all inputs
  const handleDNSRecordInputChange = (e, fieldName = "", isSelect) => {
    const finalValue = isSelect ? 
      {[fieldName]: e}
      : 
      {[e.target?.name]: e.target?.value ?? ""}

    setDnsRecordFormData((prevState) => ({
      ...prevState, // Copy previous state
      ...finalValue, // Update the specific field by its name
    }));
  };

  const handleAddDnsRecord = async () => {
    if (!dnsRecordFormData.content || !dnsRecordFormData.type) {
      alertData.title = "Field Required";
      alertData.msg = `Please fill in ${!dnsRecordFormData.content ? "content" : ""}${!dnsRecordFormData.type ? `${!dnsRecordFormData.content ? "," : ""} type` : ""}!`;
      sharedErrorAlert(alertData);
      return;
    }

    let requestData = {
      zoneId: dnsRecordFormData.zoneId,
      name: "@",
      content: dnsRecordFormData.content,
      type: dnsRecordFormData.type
    }
    let oriResponse = await addDNSRecord(requestData);
    let response = await oriResponse.json();
    if (response.status === "ok") {
      alertData.title = "Success";
      alertData.msg = response.msg;
      sharedSuccessAlert(alertData);
      onCloseDnsRecordFrom();
      handleRefreshDNSRecord();
    } else {
      alertData.title = "Failed to update dns record";
      alertData.msg = response.msg;
      sharedErrorAlert(alertData);
    }
  };

  const handleUpdateDnsRecord = async () => {
    if (!dnsRecordFormData.content || !dnsRecordFormData.type) {
      alertData.title = "Field Required";
      alertData.msg = `Please fill in ${!dnsRecordFormData.content ? "content" : ""}${!dnsRecordFormData.type ? `${!dnsRecordFormData.content ? "," : ""} type` : ""}!`;
      sharedErrorAlert(alertData);
      return;
    }
    let requestData = {
      zoneId: dnsRecordFormData.zoneId,
      recordId: dnsRecordFormData.id,
      name: "@",
      content: dnsRecordFormData.content,
      type: dnsRecordFormData.type
    }

    let oriResponse = await updateDNSRecord(requestData);
    let response = await oriResponse.json();
    if (response.status === "ok") {
      alertData.title = "Success";
      alertData.msg = response.msg;
      sharedSuccessAlert(alertData);
      onCloseDnsRecordFrom();
      handleRefreshDNSRecord();
    } else {
      alertData.title = "Failed to update dns record";
      alertData.msg = response.msg;
      sharedErrorAlert(alertData);
    }
  };

  const handleDeleteDnsRecord = async (zoneId, recordId) => {
    let requestData = {
      zoneId: zoneId,
      recordId: recordId
    }
    let oriResponse = await deleteDNSRecord(requestData);
    let response = await oriResponse.json();
    if (response.status === "ok") {
      alertData.title = "Success";
      alertData.msg = response.msg;
      sharedSuccessAlert(alertData);
      handleRefreshDNSRecord();
    } else {
      alertData.title = "Failed to delete dns record";
      alertData.msg = response.msg;
      sharedErrorAlert(alertData);
    }
  };

  const onCloseDnsRecordFrom = () => {
    setIsAddOrModifyDnsRecord(false);
    setDnsRecordFormData({name: "", content: "", type: "TXT"})
  }
  //#endregion

  //#region ---------- Renders ----------

  return (
    <div>
      {onboardDomainVw(role) ? (
        <div>
          <Modal
            title={
              dnsRecordAction === DnsRecordActionType.Create
                ? "Create DNS Record"
                : "Edit DNS Record"
            }
            visible={isAddOrModifyDnsRecord}
            // onCancel={onReset}
            footer={null}
            closeIcon={false}
            maskClosable={false}
            onCancel={onCloseDnsRecordFrom}
          >
            <Form {...layout} form={form}>
              <Form.Item
                label="Content"
                rules={[{ required: true, whitespace: true, max: 100 }]}
                onCancel={onReset}
              >
                <Input
                  value={dnsRecordFormData.content}
                  placeholder="Enter your content here… E.g. 123.123.123.123"
                  onChange={handleDNSRecordInputChange}
                  style={{ borderColor: isValidDomain ? "" : "red" }}
                  name="content"
                  // disabled={formLoading}
                />
              </Form.Item>
              <Form.Item
                label="Type"
                rules={[{ required: true, whitespace: true, max: 100 }]}
                onCancel={onReset}
              >
                <Select
                  style={{ width: 120 }}
                  onChange={(e) => handleDNSRecordInputChange(e, "type", true)}
                  value={dnsRecordFormData.type}
                >
                  <Select.Option value="TXT">TXT</Select.Option>
                </Select>
                {/* <p>
                  <i>
                    The domain should be in this specific format:{" "}
                    <b>"tkb.com"</b>
                  </i>
                </p> */}
              </Form.Item>
              <Form.Item {...tailLayout} style={{ textAlign: "right" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginRight: 8 }}
                  // loading={formLoading}
                  onClick={() => {
                    if (dnsRecordAction === DnsRecordActionType.Create) handleAddDnsRecord(); else handleUpdateDnsRecord();
                  }}
                >
                  Submit
                </Button>
                <Button
                  htmlType="button"
                  onClick={onCloseDnsRecordFrom}
                  // loading={formLoading}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </Modal>
          <Modal
            title="Add Domain"
            visible={visible}
            onCancel={onReset}
            footer={null}
            closeIcon={false}
            maskClosable={false}
          >
            <Form {...layout} form={form}>
              <Form.Item
                label="Domain"
                rules={[{ required: true, whitespace: true, max: 100 }]}
                onCancel={onReset}
              >
                <Input
                  value={newDomain}
                  placeholder="Enter your domain here… E.g. tkb.com"
                  onChange={handleInputDomain}
                  style={{ borderColor: isValidDomain ? "" : "red" }}
                  disabled={formLoading}
                />
                <p>
                  <i>
                    The domain should be in this specific format:{" "}
                    <b>"tkb.com"</b>
                  </i>
                </p>
              </Form.Item>
              <Form.Item {...tailLayout} style={{ textAlign: "right" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginRight: 8 }}
                  loading={formLoading}
                  onClick={addDomain}
                >
                  Submit
                </Button>
                <Button
                  htmlType="button"
                  onClick={onReset}
                  loading={formLoading}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          </Modal>
          <>
            <PageHeader title="Onboard Domain">
              <Button
                type="primary"
                onClick={() => {
                  setVisible(true);
                }}
              >
                Add Domain
              </Button>
              <Button
                type="primary"
                style={{ marginLeft: 10 }}
                onClick={retrieveDomainList}
              >
                Refresh List
              </Button>
            </PageHeader>
            <Table
              style={{ overflow: "auto", marginLeft: 20 }}
              loading={tableLoadings}
              columns={columns}
              dataSource={domainList}
              pagination={false}
            />
          </>
        </div>
      ) : (
        <CNotAuthorized />
      )}
    </div>
  );
}

//#endregion
