import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Input,
  Form,
  Button,
  Modal,
  Badge,
  PageHeader,
  Select,
  Checkbox,
} from "antd";
import {
  EditOutlined,
  SaveOutlined,
  CloseCircleOutlined,
  SearchOutlined,
  FolderViewOutlined,
  DeleteOutlined,
  QuestionCircleOutlined
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {
  userCSNew,
  userCSNewV2,
  userCSList,
  userManageUpdate,
  userManageUpdateV3,
  getBoUserType,
  getPageAccess,
  deletePageAccess,
  setPageAccess,
  getParentPage,
  getBoPage,
  setUserStatus,
} from "../../config/networkConfig";
import md5 from "md5";
import { useSelector } from "react-redux";
import { CS_USER_TYPE, MARKETING_USER_TYPE, REPORT_USER_TYPE } from "../../common/constants/csuserType";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert, } from "../../common/constants/sharedMsgBox";
import { boUserVw } from "../../library/permission";
import { CNotAuthorized } from "../../common/components/CNotAuthorized";

export default function CustomerServiceManagement() {
  const [form] = Form.useForm();
  const modalForm = useRef();
  const { role, userId, companyId } = useSelector((state) => state.login);
  const { Option } = Select;
  const [originData, setOriginData]           =     useState([]);
  const [editingKey, setEditingKey]           =     useState("");
  const [username, setUsername]               =     useState("");
  const [nickname, setNickname]               =     useState("");
  const [password, setPassowrd]               =     useState("");
  const [searchText, setSearchText]           =     useState("");
  const [addVisible, setAddVisible]           =     useState(false);
  const [tableLoadings, setTableLoadings]     =     useState(false);
  const [loadings, setLoadings]               =     useState([]);
  const [csUserType, setCSUserType]           =     useState("");
  const [boUserTypeList, setBoUserTypeList]   =     useState([]);

   // Page Access useState
   const [editPageAccess, setEditPageAccess] = useState(false);
   const [editPageAccessModalLoading, setEditPageAccessModalLoading] = useState(false);
   const [parentPageOptions, setParentPageOptions] = useState([]);
   const [currentParentPage, setCurrentParentPage] = useState(1);
   const [selectedPage, setSelectedPage] = useState({});
   const [userPageAccessData, setUserPageAccessData] = useState({});
   const [tableKey, setTableKey] = useState(0);
   const [editingUser, setEditingUser] = useState("");
   const [pageAccessSubmitLoading, setPageAccessSubmitLoading] = useState(false);
   const currentPageId = useSelector((state) => state.currentPageId);
   const [userAccessList, setUserAccessList] = useState([]);
   const [userAccess, setUserAccess]         = useState("");
   const { confirm } = Modal;
   const [removeUserLoading, setRemoveUserLoading] = useState(false);
  
  const md5Pass = md5(password);
  // const [userTypeSelect] = useState("");
  useEffect(() => {
    downlineList();
    retrieveUserType();
    getParentPageList();
    // retrieveUserAccess();
    // eslint-disable-next-line
  }, []);

  const downlineList = () => {
    var count = 0;
    setTableLoadings(true);
    userCSList({ userId: role == 'CS_Super' ? companyId : userId  })
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "ok") {
          setTableLoadings(false);
          const newData = Object.keys(json.data).length
            ? json.data.map((x) => {
                return {
                  key: count++,
                  userid: x.userId,
                  username: x.username,
                  nickname: x.nickname,
                  type:x.type,
                  userType: x.type,
                  // userAccess: x.userAccess,
                  lastLogin: x.lastLogin,
                  registerDate: x.registerDate,
                  serialNumber: `${x.shareCount}/${x.agentCount}/${x.memberCount}`,
                  status:
                    x.status === "0"
                      ? "Lock"
                      : x.status === "1"
                      ? "Active"
                      : "Inactive",
                };
              })
            : [];
          if(role === 'CS_Super'){
            let filteredNewData = newData.filter(obj => obj.userType !== 'CS_Super');
            setOriginData(filteredNewData);
          }else {
            setOriginData(newData);
          }
          setAddVisible(false);
        }
        else {
          setTableLoadings(true);
          alertData.title = "Failed";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        alertData.title = "Error";
        alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
        sharedErrorAlert(alertData);
      });
  };

  const loadingTrue = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = true;
    setLoadings(newLoadings);
  };

  const loadingFalse = (index) => {
    const newLoadings = [...loadings];
    newLoadings[index] = false;
    setLoadings(newLoadings);
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode =
      inputType === "setCSUserType" ? (
        <Select
          dropdownStyle={{ position: "fixed" }}
          onSelect={(value, event) => {
            setCSUserType(value, event);
          }}
        >
          {boUserTypeList.map((data) => (
            <Option key={data.key} value={data.userType}>{data.displayName}</Option>
          ))}
        </Select>
      ) : (
        <Input allowClear={true} />
      );

    //const inputNode = <Input allowClear={true} />;

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const showModal = () => {
    setEditingKey("");
    setAddVisible(true);
    form.resetFields();
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  let searchInput = null;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) setTimeout(() => searchInput.select());
    },

    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  const columns = [
    {
      title: "Username",
      dataIndex: "username",
      width: "14%",
      //width: "20%",
      ...getColumnSearchProps("username"),
      ellipsis: true,
    },
    {
      title: "Nickname",
      dataIndex: "nickname",
      width: "14%",
      // width: "20%",
      editable: true,
      ...getColumnSearchProps("nickname"),
    },
    {
      title: "User Type",
      dataIndex: "userType",
      width: "15%",
      editable: true,
      render :(text) => {
        return (
          text === CS_USER_TYPE.SuperCS || text.toLowerCase() === "cs super"
          ? "Super CS"
          : text === CS_USER_TYPE.Senior || text.toLowerCase() === "cs senior"
          ? "Senior CS"
          : text === CS_USER_TYPE.Junior || text.toLowerCase() === "cs junior"
          ? "Junior CS"
          : text === CS_USER_TYPE.CS || text.toLowerCase() === "cs"
          ? "Basic CS"
          : text === REPORT_USER_TYPE.Reporter || text.toLowerCase() === "reporter"
          ? "Reporter User"
          : text === REPORT_USER_TYPE.JR_Reporter || text.toLowerCase() === "junior reporter"
          ? "Junior Reporter User"
          : text === MARKETING_USER_TYPE.Marketing || text.toLowerCase() === "mktg"
          ? "Marketing User"
          : text.toLowerCase() === "uiux"
          ? "UI Team User"
          // : "-"
          : text
        )
      }
    },
    {
      ellipsis: true,
      title: "Register Date",
      dataIndex: "registerDate",
      width: "20%",
      sorter: (a,b) => new Date(a.registerDate) - new Date(b.registerDate),
    },
    {
      width: "15%",
      title: "Status",
      dataIndex: "status",
      render: (text, record) => {
        switch (record.status) {
          case "Lock":
            return <Badge status="warning" text="Lock" />;
          case "Active":
            return <Badge status="success" text="Active" />;
          default:
            return <Badge status="error" text="Inactive" />;
        }
      },
    },
    {
      align: "center",
      ellipsis: true,
      title: "Action",
      width: "20%",
      dataIndex: "operation",
      render: (text, record) => {
        const editable = isEditing(record);
        return editable ? (
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <Input
              id={"pwd" + record.key}
              placeholder="Change Password"
              style={{ width: "auto", textAlign: "center" }}
              // onChange={(e) => {
              //   setPassowrd(e.target.value);
              // }}
            />
            <Button
              loading={loadings[1]}
              type="dashed"
              shape="circle"
              icon={<SaveOutlined />}
              onClick={() => {
                save(record, 1);
              }}
            />
            <Button
              type="danger"
              shape="circle"
              icon={<CloseCircleOutlined />}
              onClick={() => {
                cancel(record.key);
              }}
            />
          </div>
        ) : (
          <span 
            disabled={editingKey !== ""}
          > 
            <Button type="danger" shape="circle" icon={<DeleteOutlined />} onClick={() => handleRemoveUser(record)} loading={removeUserLoading} style={{ marginRight: "20px"}}/>
            <Button type="primary" shape="circle" icon={<EditOutlined />} onClick={() => edit(record)} style={{ marginRight: "20px"}}/>
            <Button type="primary" shape="circle" icon={<FolderViewOutlined />} loading={editPageAccessModalLoading} onClick={() => onEditPageAccess(record)}/>
          </span>
          // <div 
          //   style={{
          //     flexDirection: "row",
          //     display: "flex",
          //     justifyContent: "space-evenly",
          //   }}
          // >
          //   <Button type="primary" shape="circle" icon={<EditOutlined />} onClick={() => edit(record)}/>
          //   <Button type="primary" shape="circle" icon={<EditOutlined />} onClick={() => edit(record)}/>
          // </div>
        );
      },
    },
  ];

  const handleRemoveUser = (record) => {
    setRemoveUserLoading(true);

    confirm({
      className: "confirmationModalStyle",
      title: "Confirmation",
      centered: true,
      icon: <QuestionCircleOutlined />,
      content: `Are you sure you want to remove ${record.username} user?`,
      onOk() {
          removeUser(record.userid);
      },
      onCancel() {   
        setRemoveUserLoading(false);     
      },
    });
  }

  const removeUser = async (userId) => {
    const requestData = { userId };
    const removeResponse = await setUserStatus(requestData);
    const removeResult = await removeResponse.json();

    if (removeResult.status === 'ok') {
      alertData.title = "Success";
      alertData.msg = removeResult.msg;
      sharedSuccessAlert(alertData);
      downlineList();
    } else {
      alertData.title = "Failed";
      alertData.msg = removeResult.msg;
      sharedFailAlert(alertData)
    }

    setRemoveUserLoading(false);
  }

  const isEditing = (record) => record.key === editingKey;

  const edit = async (record) => {
    form.setFieldsValue({
      nickname: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (record, index) => {
    var pwd = document.getElementById("pwd" + record.key).value;

    const row = await form.validateFields();
    const { nickname, userType } = row;

    //console.log(`record userType ${record.type} , record password >> ${pwd} ,  selected userType >> ${userType} ,  new userType >> ${newUserType} , row >> ${JSON.stringify(row)}`);

    // if (
    //   nickname === record.nickname &&
    //   document.getElementById("pwd" + record.key).value === "" && 
    //   userType === record.type
    // ) {
    //   setEditingKey("");
    //   return;
    // }

    if (
      nickname === record.nickname && userType === record.userType &&
      document.getElementById("pwd" + record.key).value === "" && 
      userType === record.type
    ) {
      setEditingKey("");
      return;
    }

    try {
      const { nickname } = row;

      const newData = [...originData];
      const indexSave = newData.findIndex((item) => record.key === item.key);

      if (index > -1) {
        loadingTrue(index);
        // let newUserType = userType.toLowerCase()==='cs senior' || userType == CS_USER_TYPE.Senior ? CS_USER_TYPE.Senior : 
        // userType.toLowerCase()==='cs junior' || userType == CS_USER_TYPE.Junior ? CS_USER_TYPE.Junior : CS_USER_TYPE.CS ;
        // console.log('save >>', newUserType);
        userManageUpdateV3({
          userid: record.userid,
          nickname: nickname,
          pass: pwd ? md5(pwd) : "null",
          // usertype : newUserType,
          usertype: userType,
        })
          .then((response) => response.json())
          .then((json) => {
            if (json.status === "ok") {
              if (userType !== record.userType) {
                handleAddDefaultAccess(userType,record.userid,true);
              }
              loadingFalse(index);
              const item = newData[indexSave];
              // item.type = item.type.toLowerCase()=='cs_senior' ? CS_USER_TYPE.Senior.replace("_"," ") : userType.toLowerCase()=='cs_junior' ? CS_USER_TYPE.Junior.replace("_"," ") : CS_USER_TYPE.CS ;
              newData.splice(indexSave, 1, { ...item, ...row });
              setOriginData(newData);
              setEditingKey("");
              alertData.title = "Success";
              alertData.msg = json.msg;
              sharedSuccessAlert(alertData);
              setOriginData([]);
              setTableLoadings(true);
              downlineList();
            }
            else {
              loadingFalse(index);
              alertData.title = "Failed";
              alertData.msg = json.msg;
              sharedFailAlert(alertData);
            }
          })
          .catch((error) => {
            console.log("error: ", error);
            alertData.title = "Error";
            alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
            sharedErrorAlert(alertData);
          });
      } else {
           loadingTrue(index);
      //   message.error("Connection not stable, please try again !");
         }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleAdd = (index) => {
    if (!username || !password || !nickname || !csUserType) {
      alertData.title = "Field Required";
      alertData.msg = "Please fill in Username, Nickname, Password, User Type !";
      sharedErrorAlert(alertData);
      return;
    }
    loadingTrue(index);
    userCSNewV2({
      userid: role == 'CS_Super' || 'Admin_CS' ? companyId : userId,
      username: username,
      nickname: nickname,
      pass: md5Pass,
      usertype: csUserType,
      role: role,
    })
      .then((response) => response.json())
      .then((json) => {
        setAddVisible(false);
        if (json.status === "ok") {
          handleAddDefaultAccess(csUserType,json.data.userId);
          downlineList();
          alertData.title = "Success";
          alertData.msg = json.msg;
          sharedSuccessAlert(alertData);
        }
        else {
          loadingTrue(index);
          alertData.title = "Failed";
          alertData.msg = json.msg;
          sharedFailAlert(alertData);
        }
        loadingFalse(index);
        modalForm.current.resetFields();
      })
      .catch((error) => {
        console.log("error: ", error);
        alertData.title = "Error";
        alertData.msg = "An unexpected error occurred. Please contact your system administrator.";
        sharedErrorAlert(alertData);
      });

  };

  // const handleAddEmptyDefaultAccess = async (userId) => {
  //   const getResponse = await getBoPage();
  //   const getResult = await getResponse.json();

  //   if (getResult.status !== 'ok') { return }
  //   console.log("result >>", getResult.data);

  //   const emptyDefault = getResult.data.map((obj) => {
  //     return {
  //       pageId: obj.PageId,
  //       status: 0,
  //       read: 0,
  //       write: 0,
  //       create: 0,
  //       delete: 0,
  //     }
  //   })

  //   const requestData = {
  //     userId,
  //     pageAccessData: emptyDefault,
  //   }

  //   const setResponse = await setPageAccess(requestData);
  //   const setResult = await setResponse.json();
  // }

  const handleAddDefaultAccess = async (userAccess, userId, deleteUser = '') => {
    // Delete user access if necessary
    if (deleteUser) {
      const deleteRequestData = { userId };
      const deleteResponse = await deletePageAccess(deleteRequestData);
      const deleteResult = await deleteResponse.json();

      if (deleteResult.status !== 'ok') return;
    }

    // Get user access template
    const getRequestData = { accessName: userAccess };
    const getResponse = await getPageAccess(getRequestData);
    const getResult = await getResponse.json();

    if (getResult.status !== 'ok') return;

    const pageAccessTemplate = getResult.data.map((obj) => {
      return {
        pageId: obj.PageId,
        status: obj.AccessStatus,
        read: obj.ReadAccess,
        write: obj.WriteAccess,
        create: obj.CreateAccess,
        delete: obj.DeleteAccess,
      }
    })
    // Set user access
    const setRequestData = {
      userId,
      pageAccessData: pageAccessTemplate
    };

    const setResponse = await setPageAccess(setRequestData);
    const setResult = await setResponse.json();
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType:
          col.dataIndex === "nickname"
          ? "nickname"
          : col.dataIndex === "userType"
          ? "setCSUserType"
          : col.dataIndex === "text",
        // inputType:
        //   col.dataIndex === "nickname"
        //   ? "nickname"
        //   : col.dataIndex === "userAccess"
        //   ? "setUserAccess"
        //   : col.dataIndex === "text",
        // inputType: col.dataIndex === "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  // const mergedColumns = columns.map((col) => {
  //   if (!col.editable) {
  //     return col;
  //   }

  //   return {
  //     ...col,
  //     onCell: (record) => ({
  //       record,
  //       inputType: col.dataIndex === "text",
  //       dataIndex: col.dataIndex,
  //       title: col.title,
  //       editing: isEditing(record),
  //     }),
  //   };
  // });

  const onCancel = () => {
    setAddVisible(false);
    modalForm.current.resetFields();
    setUserAccess("");
  };

  const retrieveUserType = async () => {
    let requestData = {
      columnRequire : [
        'displayName',
        'access',
      ],
      condition: [`WHERE status = 1`]
    }
    let response = await getBoUserType(requestData);
    let result = await response.json();
    if(result.status ==='ok'){
      if(Object.keys(result.data).length > 0){
        let count = 0;

        // Filter out obj with obj.access being "company", "shareholder", "agent"
        let boUserTypeListData = result.data
        .filter((obj) => !['Company', 'Shareholder', 'Agent'].includes(obj.access))
        .map((obj) => {
          return {
            key: count++, // Increment the count for each item
            displayName: obj.displayName,
            userType: obj.access,
          };
        });

        if(boUserTypeListData.length > 0){
          if(role === 'CS_Super' ){
            let newBoUserTypeListData = boUserTypeListData.filter(obj => obj.displayName !== 'Super CS');
            setBoUserTypeList(newBoUserTypeListData);
          }else {
            setBoUserTypeList(boUserTypeListData)
          }
        }
      }
    } else {
        alertData.title = "Failed";
            alertData.msg = result.msg;
            sharedFailAlert(alertData);
          }
  }

  // =============== Page Access =============

  const onCancelEditPageAccess = () => {
    setEditPageAccess(false);
    setUserPageAccessData({});
    setCurrentParentPage(1);
    setPageAccessSubmitLoading(false);
  }

  const getParentPageList = async () => {
    const response = await getParentPage();
    const res = await response.json();

    if (res.status === 'ok') {
      let count = 0;
      let newData = res.data.map((obj) => {
        return {
          key: count ++,
          value: obj.ParentId,
          label: obj.ParentName
        }
      })
      setParentPageOptions(newData);
    }
  }

  const onEditPageAccess = async (record) => {
    setEditPageAccessModalLoading(true);
    setEditingUser(record.userid);

    // Get latest page data from bo_page_access
    const response1 = await getBoPage();
    const res1 = await response1.json();
    if (res1.status !== 'ok') {}
    const boPageList = res1.data;

    const requestData = {
      userId: record.userid
    }
    const response = await getPageAccess(requestData);
    const res = await response.json();
    if (res.status === 'ok') {
      let boPageAccess = res.data;
      // if boPageAccess is empty data, res.data = {};
      if (boPageAccess && Object.keys(boPageAccess).length === 0) {
          // Create default access entries from boPageList
          boPageAccess = boPageList.map(page => ({
              ParentId: page.parentId,
              PageId: page.PageId,
              PageName: page.PageName,
              AccessStatus: 0,
              ReadAccess: 0,
              WriteAccess: 0,
              CreateAccess: 0,
              DeleteAccess: 0
          }));

      } 

      
      
      // else {
      //     // If boPageAccess has data, ensure it's an array
      //     boPageAccess = Array.isArray(boPageAccess) ? boPageAccess : [];
      // }

      // Step 1: Create a set of existing PageIds in boPageAccess
      const existingPageIds = new Set(boPageAccess.map(page => page.PageId));

      // Step 2: Iterate through boPageList
      boPageList.forEach(page => {
          // Check if the page is already in boPageAccess
          if (!existingPageIds.has(page.PageId)) {
              // Create a new entry with default values
              const newAccessEntry = {
                  ParentId: page.parentId,  // You may need to determine how to handle ParentId if it's required
                  PageId: page.PageId,
                  PageName: page.PageName,
                  AccessStatus: 0,
                  ReadAccess: 0,
                  WriteAccess: 0,
                  CreateAccess: 0,
                  DeleteAccess: 0
              };
              // Add the new entry to boPageAccess
              boPageAccess.push(newAccessEntry);
          }
      });

      // Format data
      const formattedData = boPageAccess.reduce((acc, item) => {
          const { ParentId, PageId, PageName, AccessStatus, ReadAccess, WriteAccess, CreateAccess, DeleteAccess } = item;
      
          const newItem = {
              key: PageId,
              pageId: PageId,
              pageName: PageName,
              status: AccessStatus,
              read: ReadAccess,
              write: WriteAccess,
              create: CreateAccess,
              delete: DeleteAccess
          };
      
          if (!acc[ParentId]) {
              acc[ParentId] = [];
          }
      
          acc[ParentId].push(newItem);
          return acc;
      }, {});

      setUserPageAccessData(formattedData);

      const createFilteredPageIds = Object.entries(formattedData).reduce((acc, [parentId, pages]) => {
          const pageIds = pages
              .filter(page => page.status === 1)
              .map(page => page.key);             
      
          acc[parentId] = pageIds;
          return acc;
      }, {});
      
      setSelectedPage(createFilteredPageIds);

      setEditPageAccess(true);
    }
    setEditPageAccessModalLoading(false);
  }

  const onCheckboxChange = (record, type) => (e) => {
    setTableKey(prevKey => prevKey + 1);

    const isChecked = e.target.checked;

    if (record.status && record.read && type === "read") {
      return;
    }

    setUserPageAccessData((prevState) => {
      // Create a copy of the existing state for immutability
      const updatedData = { ...prevState };

      // Find the index of the record within the currentParentPage array
      const currentPageData = updatedData[currentParentPage] || [];
      const recordIndex = currentPageData.findIndex((item) => item.key === record.key);

      if (recordIndex !== -1) {
          // Update the specific type (write, create, delete) based on the checkbox status
          currentPageData[recordIndex] = {
              ...currentPageData[recordIndex],
              [type]: isChecked ? 1 : 0,
          };

          // Assign the updated page data back to the state
          updatedData[currentParentPage] = currentPageData;
      }

      return updatedData;
    });
  };

  const onRowSelectChange = (newSelectedPage) => {
    setTableKey(prevKey => prevKey + 1);

    setUserPageAccessData(prevState => {
        // Get the previous selection
        const prevSelectedKeys = new Set(selectedPage[currentParentPage] || []);
        const newSelectedKeys = new Set(newSelectedPage);

        // Determine which keys were added and which were removed
        const addedKeys = [...newSelectedKeys].filter(key => !prevSelectedKeys.has(key));
        const removedKeys = [...prevSelectedKeys].filter(key => !newSelectedKeys.has(key));

        // Update the state based on added and removed keys
        const updatedData = { ...prevState };
        const currentPageData = updatedData[currentParentPage] || [];

        const updatedPageData = currentPageData.map(page => {
            // Check if the current page's key is in the added or removed keys
            const isAdded = addedKeys.includes(page.key);
            const isRemoved = removedKeys.includes(page.key);

            // If the key was added or removed, update its status accordingly
            if (isAdded) {
                return {
                    ...page,
                    status: 1,
                    read: 1,
                    write: 1,
                    create: 1,
                    delete: 1
                };
            } else if (isRemoved) {
                return {
                    ...page,
                    status: 0,
                    read: 0,
                    write: 0,
                    create: 0,
                    delete: 0
                };
            }

            // Otherwise, keep the page unchanged
            return page;
        });

        updatedData[currentParentPage] = updatedPageData;
        return updatedData;
    });

    setSelectedPage(prevState => ({
      ...prevState,
      [currentParentPage]: newSelectedPage
  }));
  };

  const onSubmitPageAccess = async () => {
    setPageAccessSubmitLoading(true);
    const requestData = { userId: editingUser}

    const deleteResponse = await deletePageAccess(requestData);
    const deleteRes = await deleteResponse.json();

    if (deleteRes.status !== 'ok') {
      // Close modal
      onCancelEditPageAccess();
      alertData.title = "Failed";
      alertData.msg = "Failed to delete page access. Please try again later!";
      sharedFailAlert(alertData);
      return;
    }

    const combinePageAccessData = (userPageAccessData) => {
      // Initialize an array to hold all values
      let combinedValues = [];
  
      // Iterate over each parent page in the userPageAccessData
      for (const pageData of Object.values(userPageAccessData)) {
          // Flatten the array of page values into the combinedValues array
          combinedValues = combinedValues.concat(
              pageData.map(({ key, ...rest }) => rest) // Exclude the key field
          );
      }
  
      return combinedValues;
    };

    const combinedArray = combinePageAccessData(userPageAccessData);
  
    const requestData2 = {
      userId: editingUser,
      pageAccessData: combinedArray
    }

    const setResponse = await setPageAccess(requestData2);
    const setRes = await setResponse.json();
   
    if (setRes.status === 'ok') {
      alertData.title = "Success";
      alertData.msg = "Page access edited successfully!";
      sharedSuccessAlert(alertData);
    } else {
      alertData.title = "Failed";
      alertData.msg = "Failed to set page access. Please try again later!";
      sharedFailAlert(alertData);
    }
    onCancelEditPageAccess();
  }

  const pageAccessColumn = [
    {
      title: ' Page Name',
      dataIndex: 'pageName',
      key: 'pageName',
    },
    {
      title: "Read Access",
      editable: true,
      align: "center",
      render: (record) => {
        return (
          <Checkbox
            defaultChecked={record.read}
            checked={record.read}
            disabled={!record.status}
            onChange={onCheckboxChange(record, 'read')}
          />
        )
      }
    },
    {
      title: "Create Access",
      editable: true,
      align: "center",
      render: (record) => {
        return (
          <Checkbox
            defaultChecked={record.create}
            checked={record.create}
            disabled={!record.status}
            onChange={onCheckboxChange(record, 'create')}
          />
        )
      }
    },
    {
      title: "Update Access",
      editable: true,
      align: "center",
      render: (record) => {
        return (
          <Checkbox
            defaultChecked={record.write}
            checked={record.write}
            disabled={!record.status}
            onChange={onCheckboxChange(record, 'write')}
          />
        )
      }
    },
    {
      title: "Delete Access",
      editable: true,
      align: "center",
      render: (record) => {
        return (
          <Checkbox
            defaultChecked={record.delete}
            checked={record.delete}
            disabled={!record.status}
            onChange={onCheckboxChange(record, 'delete')}
          />
        )
      }
    },
  ];

  const rowSelection = {
    selectedRowKeys: selectedPage[currentParentPage],
    onChange: onRowSelectChange,
  };

  return (
    <div >
      {
        boUserVw(role) ?
        <div style={{ backgroundColor: "#FFFFFF" }}>
        <div>
          <Modal title="New BO User" visible={addVisible} footer={null}>
            <Form ref={modalForm}>
              <Form.Item
                name={["user", "username"]}
                label="Username"
              >
                <Input
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item
                name={["user", "nickname"]}
                label="Nickname"
                rules={[
                  {
                    required: true,
                    message: "Please input your nickname!",
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  value={nickname}
                  onChange={(e) => {
                    setNickname(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item
                name={["user", "password"]}
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                    whitespace: true,
                  },
                ]}
              >
                <Input.Password
                  value={password}
                  onChange={(e) => {
                    setPassowrd(e.target.value);
                  }}
                />
              </Form.Item>
              <Form.Item
                name={["user", "userType"]}
                label="User Type"
                rules={[
                  {
                    required: true,
                    message: "Please select CS User Type!",
                    whitespace: true,
                  },
                ]}
              >
                <Select
                  dropdownStyle={{ position: "fixed" }}
                  value={csUserType}
                  onChange={(userType) => {
                    setCSUserType(userType);
                  }}
                >
                  {boUserTypeList.map((data) => (
                    <Option key={data.key} value={data.userType}>{data.userType}</Option>
                  ))}
                  {/* <Option value={CS_USER_TYPE.Senior}>CS Senior</Option>
                  <Option value={CS_USER_TYPE.Junior}>CS Junior</Option>
                  <Option value="CS">CS</Option> */}
                </Select>
              </Form.Item>
              {/* <Form.Item
                name={["user", "userAccess"]}
                label="User Access"
                rules={[
                  {
                    required: false,
                    message: "Please select user Access!",
                    whitespace: true,
                  },
                ]}
              >
                <Select
                  dropdownStyle={{ position: "fixed" }}
                  value={userAccess}
                  onChange={(userAccess) => {
                    setUserAccess(userAccess);
                  }}
                >
                  {userAccessList.map((data) => (
                    <Option key={data.key} value={data.accessName}>{data.accessName}</Option>
                  ))}
                  <Option value={CS_USER_TYPE.Senior}>CS Senior</Option>
                  <Option value={CS_USER_TYPE.Junior}>CS Junior</Option>
                  <Option value="CS">CS</Option>
                </Select>
              </Form.Item> */}
              <Form.Item style={{ textAlign: "right" }}>
                <Button
                  htmlType="button"
                  loading={loadings[0]}
                  onClick={onCancel}
                  style={{ marginRight: 10 }}
                >
                  Cancel
                </Button>
                <Button
                  loading={loadings[0]}
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    handleAdd(0);
                  }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        </div>
        
        <div>
          <Modal 
            title="Edit Page Access" 
            visible={editPageAccess} 
            width={1000}
            footer={[
              <Button key="cancel" onClick={onCancelEditPageAccess} loading={pageAccessSubmitLoading}>
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={onSubmitPageAccess}
                loading={pageAccessSubmitLoading}
              >
                Submit
              </Button>
            ]}
          >
            <Form layout="vertical">
              <Form.Item
                name={["page", "pageGroup"]}
                label="Page Group"
              >
                <Select
                  dropdownStyle={{ position: "fixed" }}
                  placeholder="Please select page group"
                  value={currentParentPage}
                  // loading={defaultUserTypeSelectLoading}
                  onChange={(value) => setCurrentParentPage(value)}
                  style={{ width: 300 }}
                >
                  {parentPageOptions.map((data) => (
                    <Option key={data.key} value={data.value}>{data.label}</Option>
                  ))}
                </Select>
                <Select
                  dropdownStyle={{ position: "fixed" }}
                  style={{ display: "none" }}
                  placeholder="Please select page group"
                  value={currentParentPage}
                  // loading={defaultUserTypeSelectLoading}
                  onChange={(value) => setCurrentParentPage(value)}
                >
                  {parentPageOptions.map((data) => (
                    <Option key={data.key} value={data.value}>{data.label}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
            <Table 
              key={tableKey}
              rowSelection={rowSelection} 
              columns={pageAccessColumn} 
              dataSource={userPageAccessData[currentParentPage]} 
              style={{textAlign: "center"}}
            />
          </Modal>
        </div>

      <PageHeader ghost={false}>
        <div>
          <Button key="1" type="primary" onClick={showModal}>
            Add
          </Button>
        </div>
      </PageHeader>
      <Form form={form} component={false}>
        <Table
          loading={tableLoadings}
          scroll={{ x: "100%", y: null }}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={originData}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
          style={{ overflow: "auto", marginLeft: 20 }}
        />
      </Form>
      </div>:<CNotAuthorized />
      }
    </div>
  );
}