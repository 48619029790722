import React, { useEffect, useState } from "react";
import { Table, Badge, PageHeader, Button, message, Select } from "antd";
import { useSelector } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import { userMemberList, userMemberListV2, reportAgentListV3 } from "../../config/networkConfig";
import { alertData, sharedSuccessAlert, sharedErrorAlert, sharedFailAlert } from "../../common/constants/sharedMsgBox";
import { isMobile } from "react-device-detect";
import { getByDisplayValue } from "@testing-library/react";

export default function AgentMemberManagement() {
  const { Option } = Select;
  const { userId, role } = useSelector((state) => state.login);
  const [originData, setOriginData] = useState("");
  const [loadings, setLoadings] = useState(false);
  const [isAdminCS, setIsAdminCS]           = useState(false);
  const [agentOptions, setAgentOptions]     = useState([]);
  const [agentSelection, setAgentSelection] = useState(null);

  useEffect(() => {
    if (role === "Admin_CS") {
      setIsAdminCS(true);
    } else {
      setIsAdminCS(false);
    }
  }, [role]);

  useEffect(() => {
    if (role === 'Admin_CS') {
      agentList();
    }
  }, [role]);

  useEffect(() => {
    memberList();
    // eslint-disable-next-line
  }, []);

  const agentList = async () => {
    if(role === 'Admin_CS'){
      try{
        let requestData = {
            role : role,
            userId: userId,
        }

        const response = await reportAgentListV3(requestData);
        let result = await response.json();
        
        if (result.status === 'ok') {
            setAgentOptions(result.msg != 'No record.' ? result.data : []);
        }
        else{
            alertData.title = "Failed";
            alertData.msg = result.msg;
            sharedFailAlert(alertData);
        }  
      }
      catch (error) {
          alertData.title = "Error";
          alertData.msg = "Failed to fetch agent list";
          sharedFailAlert(alertData);
      }
    }
  }

  const memberList = async() => {
    var count = 0;
    setLoadings(true);
    userMemberListV2({ userId: userId, role: role })
      .then((response) => response.json())
      .then((json) => {
        console.log("userMemberList json: ", json);
        if (json.status === "ok") {
          setLoadings(false);
          const newData = Object.keys(json.data).length
            ? json.data.map((data) => {
                return {
                  key: count++,
                  nickname: data.nickname,
                  lastLogin: data.lastLogin,
                  status:
                    data.status === "0"
                      ? "Lock"
                      : data.status === "1"
                      ? "Active"
                      : "Inactive",
                  registerDate: data.registerDate,
                };
              })
            : [];
          setOriginData(newData);
          console.log("newData: ", newData);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        message.error(
          "An unexpected error occurred. Contact your system administrator "
        );
      });
  };

  const searchMemberList = async() => {
    var count = 0;
    setLoadings(true);
    userMemberListV2({ userId: agentSelection, role: 'Agent' })
      .then((response) => response.json())
      .then((json) => {
        console.log("userMemberList json: ", json);
        if (json.status === "ok") {
          setLoadings(false);
          const newData = Object.keys(json.data).length
            ? json.data.map((data) => {
                return {
                  key: count++,
                  nickname: data.nickname,
                  lastLogin: data.lastLogin,
                  status:
                    data.status === "0"
                      ? "Lock"
                      : data.status === "1"
                      ? "Active"
                      : "Inactive",
                  registerDate: data.registerDate,
                };
              })
            : [];
          setOriginData(newData);
          console.log("newData: ", newData);
        }
      })
      .catch((error) => {
        console.log("error: ", error);
        message.error(
          "An unexpected error occurred. Contact your system administrator "
        );
      });
  };

  const searchBar = () => {
  if (role === 'Admin_CS' && !agentSelection) {
      alertData.title = "Field Required";
      alertData.msg = "Please select a shareholder !";
      sharedErrorAlert(alertData);
      return;
  }
  setLoadings(true);
  searchMemberList();
  }

  const refreshBtn = () => {
    memberList();
    setAgentSelection(null);
  };

  const columns = [
    {
      align: "center",
      title: "Nickname",
      dataIndex: "nickname",
      width: 200,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Last Login",
      dataIndex: "lastLogin",
      width: 200,
      ellipsis: true,
    },
    {
      align: "center",
      title: "Status",
      dataIndex: "status",
      width: 200,
      ellipsis: true,
      render: (text, record) => {
        switch (record.status) {
          case "Lock":
            return <Badge status="warning" text="Lock" />;
          case "Active":
            return <Badge status="success" text="Active" />;
          default:
            return <Badge status="error" text="Inactive" />;
        }
      },
    },
    {
      align: "center",
      title: "Register Date",
      dataIndex: "registerDate",
      width: 200,
      ellipsis: true,
    },
  ];

  const styles = {
    mainContainter: {
      padding: 30,
    },
    pageHeader: {
      display: "flex",
      gap: "10px",
    },
  };

  return (
    <div style={styles.mainContainter}>
      <PageHeader
        ghost={false}
        style={styles.pageHeader}
      >
      <div style={{...styles.pageHeader, marginBottom: 10 }}>
      {isAdminCS && (
          <div style={{textAlign:"left", justifyContent:"left"}}>
            <Select
              value={agentSelection}
              style={{width: 200}}
              optionFilterProp="children"
              filterOption={(input, option) => {
                option.children.toLowerCase().includes(input.toLowerCase())
              }}
              placeholder="Please select an agent"
              getPopupContainer={(trigger) => trigger.parentNode}
              onChange={(value) => setAgentSelection(value)}
            >
              {agentOptions.map((option) => (
                <Option key={option.key} value={`${option.userID}`}>
                  {option.username}
                </Option>
              ))}
            </Select>
            <Button
              type={"primary"}
              icon={<SearchOutlined
              onClick={searchBar}
              />}
            />
          </div>
        )}
        <Button
          key="5"
          type="primary"
          onClick={() => {
            refreshBtn();
          }}
        >
          Refresh Page
        </Button>
      </div>
        {/* <div style={{ textAlign: "center" }}>
          <div style={{ fontSize: 25 }}>Member List</div>
        </div> */}
      </PageHeader>
      <Table
        loading={loadings}
        columns={columns}
        dataSource={originData}
        scroll={{ x: isMobile ? "110%" : "100%", y: null }}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["5", "10", "20"],
          style: styles.paginationStyle,
        }}
      />
    </div>
  );
}
